import React, { Component } from "react";
import "./FormLoading.scss";

interface Props {
}

class FormLoading extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div id="center">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <p>Please wait while the app is loading …</p>
        <p>This can take up to 30 seconds.</p>
      </div>
    );
  }
}

export default (FormLoading);
