import { APIService } from '../../shared/services/APIService';
import { FeedbackDTO } from '../dto/FeedbackDTO';
import i18n, {TFunction} from "i18next";
import {FormService} from "./FormService";

export class FeedbackService extends APIService {
  submitFeedback(dto: FeedbackDTO) {
    const formData = new FormData();
    formData.append('comment', dto.comment);
    if (dto.screenshot) {
      formData.append('screenshot', dto.screenshot);
    }
    return this.http.post('/feedback', formData);
  }

  submitBbriFeedback(dto: FeedbackDTO) {
    const formData = new FormData();
    formData.append("comment", dto.comment)
    formData.append("userEmail", dto.userEmail)
    formData.append("application", dto.application ?? "")
    formData.append("language", i18n.language)
    if (dto.screenshot) {
      formData.append("screenshot", dto.screenshot)
    }

    return this.http.post("/feedback/bbri", formData)
  }
}