import { TFunction } from "i18next";
import React, { Component, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./FormRadioImage.scss";
import { RichText } from "prismic-reactjs";
import { FormService } from "../services/FormService";

interface Props extends WithTranslation {
  currentValue: string;
  values: string[];
  images: string[];
  onValueChange: (v: string) => void;
}

interface States {
  value: string;
}

class FormRadioImage extends Component<Props, States> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.t = props.t;
    this.formService = new FormService();

    this.state = {
      value: this.props.currentValue,
    };
  }

  /**
   * Handle: onClick on radio-image-wrapper
   */
  handleClick(value: string) {

    this.props.onValueChange(value);

    this.setState({
      value,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any) {
    if (this.props.currentValue !== this.state.value) {
      this.setState({
        value: this.props.currentValue
      });
    }
  }

  render() {
    let imageList: ReactNode[] = [];

    for (let i = 0; i < this.props.values.length; i++) {
      const value = this.props.values[i];
      const image = this.props.images[i];
      let className = "radio-image-wrapper";

      if (this.state.value === value) {
        className += " radio-image-selected";
      }

      imageList.push(
        <div key={value} className={className} onClick={() => this.handleClick(value)}>
          <div className="radio-image">
            <img src={image} alt={RichText.asText(this.t(value, { returnObjects: true }))}/>
          </div>
          <div
            className="radio-image-label">{this.formService.checkIfValue(RichText.render(this.t(value, { returnObjects: true })), value)}</div>
        </div>
      );
    }

    return <div className="radio-image-container">{imageList}</div>;
  }
}

export default withTranslation()(FormRadioImage);
