import { Radio } from "@bbri/ui";
import { TFunction } from "i18next";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RichText } from "prismic-reactjs";
import { FormService } from "../services/FormService";

interface Props extends WithTranslation {
  name: string;
  currentValue: boolean | null;
  onValueChange: (v: any) => void;
}

class FormBool extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  render() {
    return (
      <div className="p-grid p-formgrid">
        <div className="p-col-12">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => this.props.onValueChange(true)}>
            <Radio
              id={this.props.name + "yes"}
              label={this.formService.checkIfValue(RichText.asText(this.t("oui", { returnObjects: true })), "oui")}
              checked={this.props.currentValue === true}
              onChange={() => this.props.onValueChange(true)}
            />
          </a>
        </div>
        <div className="p-col-12">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => this.props.onValueChange(false)}>
            <Radio
              id={this.props.name + "no"}
              label={this.formService.checkIfValue(RichText.asText(this.t("non", { returnObjects: true })), "non")}
              checked={this.props.currentValue === false}
              onChange={() => this.props.onValueChange(false)}
            />
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormBool);
