import { ResponseDTO } from './ResponseDTO';

export class ResponsesDTO {

  responses: ResponseDTO[];

  constructor(
    values: Record<string, any>
  ) {
    this.responses = [];
    for (const questionKey in values) {
      this.responses.push(
        new ResponseDTO(questionKey, values[questionKey])
      );
    }

  }
}