import { APIService } from '../../shared/services/APIService';
import {AxiosResponse} from "axios";

export class AdminService extends APIService {

    downloadAll() {
        return this.http.get<Blob>('/download', { responseType: 'blob' })
    }

    uploadAll(file: File) {
        const dbData = new FormData();
        dbData.append('db', file);
        return this.http.post<void>(`/upload`, dbData);
    }

    getVersion() {
        return this.http.get<string>('/version')
    }
}
