import { DiagramComment } from '../../form/models/DiagramComment';
import { APIService } from '../../shared/services/APIService';

export class AdminDiagramCommentService extends APIService {

  // Get all diagrams
  getMany() {
    return this.http.get<DiagramComment[]>("/diagramComments")
  }

  /**
   * Upload and replace the form
   *
   * @role Admin
   */
  uploadManyDiagramComments(file: File) {
    const formData = new FormData();
    formData.append('diagramComments', file);
    return this.http.put<void>('/diagramComments/uploadmany', formData);
  }

  updateComment(comment : DiagramComment) {
    return this.http.put('/diagramComments/updateone', comment)
  }

  deleteComment(number: number) {
    return this.http.delete("/diagramComments/" + number)
  }

}