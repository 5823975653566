import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FileUtils } from "../../shared/utils/FileUtils";
import { AdminFormService } from "../services/AdminFormService";
import { RichText } from "prismic-reactjs";
import { FormService } from '../../form/services/FormService';
import {Dialog} from "primereact/dialog";

interface Props extends WithTranslation {
}

interface States {
  displayDialog: boolean;
  error: string;
  file: File[]
}

class AdminForm extends React.Component<Props, States> {
  private readonly fService: FormService;
  private readonly formService: AdminFormService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.fService = new FormService();
    this.formService = new AdminFormService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;

    this.state = {
      displayDialog: false,
      error: "",
      file: []
    }
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }
    else {
      this.setState({
        file: e.files
      })
    }
    this.formService
      .uploadForm(e.files[0])
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.fService.t(this.t, "ui_admin_form_toast_success", false),
        });
        this.fileUpload?.clear();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.setState({
          displayDialog: true,
          error: message
        })
        // this.toast?.show({
        //   severity: SeverityEnum.ERROR,
        //   detail: `${this.fService.t(this.t, "ui_admin_form_toast_error", false)} : ${message}`,
        // });
      });
  }

  handleForceUpload() {
    this.formService
        .forceUploadForm(this.state.file[0])
        .then(() => {
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: this.fService.t(this.t, "ui_admin_form_toast_success", false),
          });
          this.setState({
            displayDialog: false
          })
          this.fileUpload?.clear();
        })
        .catch((err: any) => {
          const message = err?.response?.data?.message;
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: `${this.fService.t(this.t, "ui_admin_form_toast_error", false)} : ${message}`,
          });
        });
  }

  /**
   * On download
   */
  handleDownload() {
    this.formService.downloadForm().then((response: AxiosResponse<Blob>) => {
      FileUtils.downloadFile(response);
    });
  }

  renderDialogFooter() {
    return (
        <div>
          <Button
              label="No"
              icon="pi pi-times"
              onClick={() => this.setState({ displayDialog: false })}
              className="p-button-text"
          />
          <Button
              label="Yes"
              icon="pi pi-check"
              onClick={() => this.handleForceUpload()}
              autoFocus
          />
        </div>
    );
  }

  render() {
    return (
        <>
          <Card title={RichText.asText(this.t("ui_admin_form_title", { returnObjects: true }))}>
            <Toast ref={(el) => (this.toast = el)}/>
            <Button
              label={RichText.asText(this.t("ui_admin_form_download_button", { returnObjects: true }))}
              className="p-mb-2"
              onClick={() => this.handleDownload()}
            />
            <FileUpload
              ref={(el) => (this.fileUpload = el)}
              accept="application/json"
              customUpload={true}
              uploadHandler={(e: { files: File[] }) => this.handleUpload(e)}
              // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_form_upload")}</p>}
            ></FileUpload>
          </Card>
          <Dialog
              header="Are you sure you want to proceed ?"
              visible={this.state.displayDialog}
              modal
              style={{ width: "350px" }}
              footer={this.renderDialogFooter()}
              onHide={() => this.setState({ displayDialog: false })}
          >
            <div className="confirmation-content">
              <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
              />
              <p>There are some error in the form : {this.state.error}, are you sure you want to proceed ?</p>
            </div>
          </Dialog>
        </>
    );
  }
}

export default withTranslation()(AdminForm);
