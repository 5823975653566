import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { FormService } from "../services/FormService";
import React from "react";
import { Modal } from "@bbri/ui";
import "./NoConnectionModal.scss";

interface Props extends WithTranslation {
}

class NoConnectionModal extends React.Component<Props> {

  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.formService = new FormService();
  }

  render() {
    return (
      <Modal open={true} id="offline" backdrop>
        <p>{(this.formService.t(this.t, "no_connection", false) !== 'no_connection') ?
          (this.formService.t(this.t, "no_connection", false)) :
          'No Internet connection. Please connect.'}</p>
      </Modal>
    );
  }

}

export default withTranslation()(NoConnectionModal);
