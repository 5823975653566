import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { DecisionTreeResultDTO } from '../dto/DecisionTreeResultDTO';
import { ResponsesDTO } from '../dto/ResponsesDTO';
import {Form} from "../models/Form";
import {DecisionTree} from "../../admin/models/DecisionTree";
import {Html} from "../../admin/models/HTML";

export class DecisionTreeService extends APIService {

  /**
   * Compute decision tree
   */
  async computeDecisionTree(name: string, responses: Record<string, any>) {
    const dto = new ResponsesDTO(responses);
    return this.http.post<DecisionTreeResultDTO>('/decision-trees/' + name + '/compute', dto)
      .then((response: AxiosResponse<DecisionTreeResultDTO>) => {
        return response.data.result;
      })
        .catch((e) => {
          console.log(e)
        });
  }

    getAllDecisionTrees(): Promise<AxiosResponse<DecisionTree[]>> {
      return this.http.get<DecisionTree[]>('/decision-trees');
    }
}
