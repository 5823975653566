import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { Toast } from "primereact/toast";
import { TFunction } from "i18next";
import { FormService } from "../../form/services/FormService";
import { DecisionTreeEditorService } from "../services/DecisionTreeEditorService";
import { TranslationService } from "../../form/services/TranslationService";
import { RequirementsService } from "../services/RequirementsService";
import { DecisionTreeService } from "../../form/services/DecisionTreeService";
import { Form } from "../../form/models/Form";
import { FormEditorService } from "./services/FormEditorService";
import './FormEditor.scss';
import FormEditorSidebar from "./components/FormEditorSidebar";
import FormEditorTopbar from "./components/FormEditorTopbar";
import FormEditorWorkPlace from "./components/FormEditorWorkPlace";
import { DecisionTree } from "../../admin/models/DecisionTree";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FormType } from "../../form/enum/FormType";

interface Props extends WithTranslation {

}

interface States {
  form: Form | null;
  questions: any[];
  currentQuestion: any | null;
  prismicKeys: string[];
  decisionTrees: DecisionTree[];
  currentQuestionNameTwo: string | null;
}

class FormEditor extends React.Component<Props, States> {
  private toast: Toast | null;
  private t: TFunction;
  private formService: FormService;
  private decisionTreeEditorService: DecisionTreeEditorService;

  private translationService: TranslationService;
  private requirementService: RequirementsService;
  private decisionTreeService: DecisionTreeService;
  private formEditorService: FormEditorService;


  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.toast = null;
    this.formService = new FormService();

    this.translationService = new TranslationService();
    this.requirementService = new RequirementsService();
    this.decisionTreeEditorService = new DecisionTreeEditorService();
    this.decisionTreeService = new DecisionTreeService();
    this.formEditorService = new FormEditorService();

    this.state = {
      form: null,
      questions: [],
      currentQuestion: null,
      prismicKeys: [],
      decisionTrees: [],
      currentQuestionNameTwo: null
    };

    this._getQuestions();
    this._getPrismicKeys();
    this._getDecisionTrees();

  }

  private _getQuestions() {
    this.formEditorService.getForm().then((res) => {
      if (res.data) {
        this.setState({
          form: res.data,
          questions: res.data.questions
        });
      }
    });
  }

  /**
   * Load prismic keys
   */
  private _getPrismicKeys() {
    this.translationService.get('withoutCommon/fr')
      .then((res) => {

        let transformObj: any[] = [];

        for (let i = 0; i < Object.keys(res.data).length; i++) {
          transformObj.push({ name: Object.keys(res.data)[i], label: Object.keys(res.data)[i] });
        }

        this.setState({
          prismicKeys: transformObj
        });
      });
  }

  /**
   * Load decision trees
   */
  private _getDecisionTrees() {
    this.decisionTreeService.getAllDecisionTrees()
      .then((res) => {
        this.setState({
          decisionTrees: res.data
        });
      });
  }


  private _refreshQuestionList() {
    this._getQuestions();
  }

  private async _renameQuestion(question: any, currentName: string) {
    let questions = this.state.form?.questions as any;
    if (questions) {
      for (let q of questions) {
        if (q.question === currentName) {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: 'Une question avec ce nom existe déjà !',
          });
          return false;
        }
        if (q.question === question.question) {
          q.question = currentName;
        }
      }
    }
    return this.formEditorService.updateQuestions(questions);
  }


  render() {
    return (
      <div className="editor-script">
        <Toast ref={(el) => this.toast = el}/>
        <div className="sideBar">
          <FormEditorSidebar
            questions={this.state.questions}
            currentQuestion={this.state.currentQuestion}
            form={this.state.form}
            decisionTrees={this.state.decisionTrees}
            onQuestionSelection={(question) => {
              if (question.question === this.state.currentQuestion?.question) {
                if (this.state.currentQuestion && (question !== this.state.currentQuestion)) {
                  Object.assign(question, this.state.currentQuestion);
                }
              }
              this.setState({
                currentQuestion: question
              });
            }}
            saveFormConfig={(formConfig => {
              const form = this.state.form as any;
              if (form) {
                form.googleTag = formConfig.googleTag ?? '';
                form.prismic = formConfig.prismic ?? [];
                form.type = formConfig.type ?? FormType.REQUIREMENT
                form.pdfWidth = formConfig.pdfWidth;
                form.docxGeneration = formConfig.docxGeneration;
                form.pdfFromDocx = formConfig.pdfFromDocx;
                form.mailTo = formConfig.mailTo;
                form.graphDecisionTree = formConfig.graphDecisionTree ?? null
                form.diagramDecisionTree = formConfig.diagramDecisionTree ?? null
                this.setState({
                  form
                }, () => {
                  this.formEditorService.updateForm(this.state.form).then((res) => {
                    if (res) {
                      this.toast?.show({
                        severity: SeverityEnum.SUCCESS,
                        detail: 'La configuration du formulaire a bien été mise à jour !',
                      });
                    }
                  });
                });
              }
            })}
            deleteAllQuestion={() => {
              this.formEditorService.updateQuestions([]).then(res => {
                if (res) {
                  this.toast?.show({
                    severity: SeverityEnum.SUCCESS,
                    detail: 'Les questions ont bien été supprimées !',
                  });
                  this._getQuestions();
                  this.setState({
                    currentQuestion: null
                  });
                }
              });
            }}
            refreshQuestionList={() => {
              this._getQuestions();
            }}
            prismicKeys={this.state.prismicKeys}
            addQuestion={(question => {
              let questions = this.state.questions;
              questions.push(question);
              this.formEditorService.updateQuestions(questions).then((res) => {
                if (res) {
                  this.toast?.show({
                    severity: SeverityEnum.SUCCESS,
                    detail: 'Question added !',
                  });
                  this._getQuestions();
                }
              });
            })}
          />
        </div>
        <div className="workPlace">
          <div className="topBarFormEditor">
            <FormEditorTopbar
              currentQuestion={this.state.currentQuestion}
              currentForm={this.state.form}
              refreshQuestionList={() => this._refreshQuestionList()}
              deleteQuestion={(question => {
                let questions = this.state.questions;
                for (let i in questions) {
                  if (questions[i].question === question.question) {
                    questions.splice(parseInt(i), 1);
                    this.formEditorService.updateQuestions(questions).then((res) => {
                      if (res) {
                        this.toast?.show({
                          severity: SeverityEnum.SUCCESS,
                          detail: 'Question supprimée !',
                        });
                        this.setState({ currentQuestion: null });
                      }
                    });
                  }
                }
              })}
              updateQuestionName={(question, currentName) => {
                return this._renameQuestion(question, currentName);
              }}
              saveForm={() => {
                const questions = this.state.questions;
                for (const q of questions) {
                  if (q.question === this.state.currentQuestion.question) {
                    if (q !== this.state.currentQuestion) {
                      Object.assign(q, this.state.currentQuestion);
                    }
                    if (this.state.currentQuestionNameTwo && this.state.currentQuestionNameTwo !== q.question) {
                      q.question = this.state.currentQuestionNameTwo;
                    }
                    if (q.newName) {
                      q.question = q.newName;
                      delete q.newName;
                    }
                    this.setState({
                      currentQuestion: q
                    });
                  }
                }
                this.formEditorService.updateQuestions(questions).then((res) => {
                  if (res) {
                    this._refreshQuestionList();
                    this.toast?.show({
                      severity: SeverityEnum.SUCCESS,
                      detail: 'Question saved !',
                    });
                  }
                });
              }}
            />
          </div>
          <div id="workPlace">
            <FormEditorWorkPlace
              currentQuestion={this.state.currentQuestion}
              prismicKeys={this.state.prismicKeys}
              questions={this.state.questions}
              decisionTrees={this.state.decisionTrees}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormEditor);
