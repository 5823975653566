import { withTranslation, WithTranslation } from "react-i18next";
import { Schema } from "../../models/Schema";
import React from "react";
import { TFunction } from "i18next";
import { DecisionTreeEditorService } from "../../services/DecisionTreeEditorService";
import { Menu } from "primereact/menu";
import { Button } from "primereact/components/button/Button";
import { Dialog } from "primereact/dialog";
import "./sidebar.scss";
import { SeverityEnum } from "../../../shared/enum/SeverityEnum";
import draw2d from "draw2d";
import { Toast } from "primereact/toast";
import { NODE_LIST } from "../enums/shape";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";

interface States {
  schemas: Schema[] | null;
  schemasItems: any[];
  currentSchema: Schema | null;
  isSchemasRefreshing: boolean;
  showNotSaveDialog: boolean;
  menuFilter: string;
  loading: boolean;
}

interface Props extends WithTranslation {
  onClick: any;
  refreshSchemas: boolean;
  newTree: () => void;
  resetRefreshSchemas: () => void;
  currentSchemaName: string | null;
  currentKeyName: string | null;
  changes: boolean;
  resetChanges: () => void;
  view: any;
  schema: Schema | null;
  setChanges: (changes: boolean) => void;
  setCurrentToolsName: (currentToolsName: string) => void;
}

class Sidebar extends React.Component<Props, States> {

  private t: TFunction;
  private decisionTreeEditorService: DecisionTreeEditorService;
  private schemaMenu: any;
  private toast: Toast | null;
  private schemas: any = [];

  constructor(props: Props) {
    super(props);

    this.decisionTreeEditorService = new DecisionTreeEditorService();
    this.t = this.props.t;
    this.toast = null;
    this.state = {
      schemas: null,
      schemasItems: [],
      currentSchema: null,
      isSchemasRefreshing: false,
      showNotSaveDialog: false,
      menuFilter: '',
      loading: false
    };

  }


  componentDidMount() {
    this.setState({
      loading: true
    }, () => {
      this.decisionTreeEditorService.findAll().then((res) => {
        this.setState({
          schemas: res.data,
          loading: false
        });
        this._refreshMenuItems();
      });
    });

  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {

    const schema = {
      name: nextProps.currentSchemaName,
      key: nextProps.currentKeyName,
      json: this.state.currentSchema?.json
    };
    if (nextProps.refreshSchemas) {
      // @ts-ignore
      this.setState({
        isSchemasRefreshing: true,
        currentSchema: this.props.schema
      });
      this._refreshMenuItemsWithAPI();
    }
  }

  private _refreshMenuItemsWithAPI() {
    this.decisionTreeEditorService.findAll().then((res) => {
      this.setState({
        schemas: res.data
      });
      this._refreshMenuItems();
    });
  }

  private _refreshMenuItems() {
    this.state.schemasItems.splice(0, this.state.schemasItems.length);
    if (this.state.schemas) {
      const values: any[] = [];
      for (const schema of this.state.schemas) {
        values.push({
          label: schema.name,
          schema,
          disabled: (!schema.isEditor || schema.key === this.state.currentSchema?.key),
          style: {
            backgroundColor: (schema.key === this.state.currentSchema?.key) ? '#85bdbe' : ''
          },
          command: (e) => {
            if (this.props.changes) {
              this.schemaMenu = e;
              this.setState({
                showNotSaveDialog: true
              });
            }
            else {
              this.setState({
                currentSchema: e.item.schema
              }, () => {
                this._refreshMenuItems();
                this.props.onClick(e.item.schema);
              });
            }
          }
        });
      }
      this.schemas = values;
      this.setState({
        schemasItems: values
      });
      if (this.state.isSchemasRefreshing) {
        this.props.resetRefreshSchemas();
        this.setState({
          isSchemasRefreshing: false
        });
      }
    }
  }

  private _newTree() {
    this.props.newTree();
  }

  saveSchema() {
    const writer = new draw2d.io.json.Writer();
    writer.marshal(this.props.view, json => {
      if (this.props.schema) {
        const schema = new Schema(this.props.schema.key, this.props.schema.name, JSON.stringify(json));
        this.decisionTreeEditorService.saveSchema(schema).then(() => {
          this.props.setChanges(false);
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: 'Schema saved',
          });
        }).catch((e) => {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: `Decision tree is invalid: ${e.response.data.error?.message ?? 'Error not communicated'}`,
          });
        });
      }
      else {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: 'Please select a schema',
        });
      }
    });
  }

  private _renderNotSaveFooter() {
    return (
      <div>
        <Button
          label="Back to the edition"
          onClick={() => this.setState({ showNotSaveDialog: false })}
          className="p-button-outlined p-mb-2"
        />
        <Button
          label="Save and leave"
          icon="pi pi-check"
          onClick={() => {
            this.saveSchema();
            this.setState({ showNotSaveDialog: false });
            this.setState({
              currentSchema: this.schemaMenu.item.schema
            }, () => {
              this._refreshMenuItems();
              this.props.onClick(this.schemaMenu.item.schema);
            });
          }}
          className="p-mb-2"
        />
        <Button
          label="Leave without saving"
          icon="pi pi-times"
          onClick={() => {
            this.props.resetChanges();
            this.setState({ showNotSaveDialog: false });
            this.setState({
              currentSchema: this.schemaMenu.item.schema
            }, () => {
              this._refreshMenuItems();
              this.props.onClick(this.schemaMenu.item.schema);
            });
          }}
          className="p-button-danger p-mb-2"
        />
      </div>
    );
  }

  private _getNodeList(end: boolean) {
    let content: any[] = [];
    for (const i in NODE_LIST) {
      if (end) {
        if (NODE_LIST[i].end) {
          content.push(
            <div className={`circle palette_node_element draw2d_droppable ${NODE_LIST[i].className}`}
                 onMouseEnter={() => this.props.setCurrentToolsName('Node: ' + NODE_LIST[i].name)}
                 onMouseLeave={() => this.props.setCurrentToolsName('')}
                 title={NODE_LIST[i].defaultLabel}
                 data-shape={NODE_LIST[i].name}
                 style={{ backgroundColor: NODE_LIST[i].iconBackgroundColor }}>
              <img
                src={`https://img.icons8.com/${NODE_LIST[i].iconStyle}/96/${NODE_LIST[i].iconColor}/${NODE_LIST[i].icon}.png`}
                alt="node icon"/>
            </div>
          );
        }
      }
      else {
        if (!NODE_LIST[i].end) {
          content.push(
            <div className={`circle palette_node_element draw2d_droppable ${NODE_LIST[i].className}`}
                 onMouseEnter={() => this.props.setCurrentToolsName('Node: ' + NODE_LIST[i].name)}
                 onMouseLeave={() => this.props.setCurrentToolsName('')}
                 title={NODE_LIST[i].defaultLabel}
                 data-shape={NODE_LIST[i].name}
                 style={{ backgroundColor: NODE_LIST[i].iconBackgroundColor }}>
              <img
                src={`https://img.icons8.com/${NODE_LIST[i].iconStyle}/96/${NODE_LIST[i].iconColor}/${NODE_LIST[i].icon}.png`}
                alt="node icon"/>
            </div>
          );
        }
      }
    }
    return content;

  }

  render() {
    return (
      <div id="side-nav">
        <Toast ref={(el) => (this.toast = el)}/>
        <div>
          <InputText
            placeholder="Filter by name"
            className="menuFilter"
            value={this.state.menuFilter}
            onChange={(e: any) => {
              this.setState({
                menuFilter: e.target.value
              });
              if (e.target.value) {
                const schemas = this.schemas;
                const schemasFiltered: any = [];
                for (const schema of schemas) {
                  if (schema.label.includes(e.target.value)) {
                    schemasFiltered.push(schema);
                  }
                }
                this.setState({
                  schemasItems: schemasFiltered
                });
              }
              else {
                this._refreshMenuItems();
              }
            }}
          />
          <tr>
            <th className="decisionTreeListTitle"><span
              className="p-column-title">List of trees</span></th>
            <th className="" style={{ backgroundColor: '#f8f9fa' }}><span className="p-column-title"></span>
            </th>
          </tr>
          <Menu className="menuList" model={this.state.schemasItems}/>
        </div>
        <div className="nodesList">
          <div className="drag-elements">
            <div><h2>NODES</h2></div>
            <div className="elements">
              {
                this._getNodeList(false)
              }
            </div>
          </div>
          <div className="drag-elements">
            <div><h2>END NODES</h2></div>
            <div className="elements">
              {
                this._getNodeList(true)
              }
            </div>

          </div>
        </div>
        <Toolbar
          left={() => (
            <div>
              <Button
                icon="pi pi-plus"
                tooltip="Add new tree"
                style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                onClick={() => {
                  this._newTree();
                }}
                className="p-button-primary"/>
            </div>
          )}
        />
        <Dialog
          visible={this.state.showNotSaveDialog}
          onHide={() => this.setState({ showNotSaveDialog: false })}
          header="Changes detected !"
          footer={this._renderNotSaveFooter()}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }}/>
            <span>Please choose an action !</span>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(Sidebar);
