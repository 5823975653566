import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FormService } from "../../form/services/FormService";

import Editor from "@monaco-editor/react";
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

import { AdminScriptsService } from "../services/AdminScriptsService";
import { ScriptDTO } from "../dto/ScriptDTO";

import CodeSidebarJS from "./components/JS/CodeSidebarJS";
import QuestionSelectorJS from "./components/JS/QuestionSelectorJS";
import "./CodeEditor.scss";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";

interface Props extends WithTranslation {
}

interface States {
  displayConfirmation: boolean;
  displayNewScriptConfirmation: boolean;
  editorText: string;
  editorScriptName: string;
  refreshScriptsState: boolean;
  editorRef: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | null>;
  deleteScript: boolean;
  downloadScript: boolean;
}


class CodeEditorJS extends React.Component<Props, States> {


  private toast: Toast | null;
  private t: TFunction;
  private formService: FormService;

  private readonly scriptService: AdminScriptsService;
  private readonly scriptDefaultScript: string;

  private leftContents: any;
  private rightContents: any;

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.toast = null;
    this.formService = new FormService();

    // Languages
    this.scriptService = new AdminScriptsService();
    this.scriptDefaultScript =
      '//Script structure :\n\n'
      + '/*\nvar answer = responses.get("<QUESTION_NAME>");'
      /* +'\n\nanswer.foreach((value)=>{\n\tvalue+=1;\n})' */
      + '\n\ndata.result.push(answer); ou data.result = answer;\n*/\n\n';

    this.state = {
      displayConfirmation: false,
      editorText: "",
      editorScriptName: "",
      displayNewScriptConfirmation: false,
      refreshScriptsState: false,
      editorRef: React.createRef<monaco.editor.IStandaloneCodeEditor | null>(),
      deleteScript: false,
      downloadScript: false,
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.resetEditorText = this.resetEditorText.bind(this);
    this.handleOneUpload = this.handleOneUpload.bind(this);
    this.handleEditorDidMount = this.handleEditorDidMount.bind(this);
  }

  public componentDidMount(): void {
    /* this.editorRef.current?.editor?.layout();
    if (autofocus) {
        this.editorRef.current?.editor?.focus();
    } */
  }

  private refreshScript = (scriptToView) => {

    this.setState(
      {
        editorScriptName: scriptToView
      }
    );

    this.getScriptToViewText(scriptToView);
  };


  // Languages
  getScriptToViewText(str: string) {
    if (!str || !str.includes(".js")) {
      return;
    }
    this.scriptService
      .getOne(str)
      .then((response: AxiosResponse<string>) => {

        this.setState({ editorText: response.data, editorScriptName: str });
      });
  }


  /**
   * On upload for one script
   */
  handleOneUpload() {

    let fileName = this.state.editorScriptName;

    if (!fileName) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `Please enter a script name !`,
      });
      return;
    }

    let extensionPart = fileName.substring(fileName.lastIndexOf('.'));

    const allowedTypes = ['.js'];

    // = Validate the types
    if (!allowedTypes.includes(extensionPart)) {
      fileName += ".js";
    }

    const dto = new ScriptDTO(fileName, this.state.editorText);

    this.scriptService
      .uploadOne(dto)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: 'Script sauvegardé !',
        });

        this.setState({ refreshScriptsState: true });

      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `Une erreur est survenue lors de la sauvegarde du script: ${message}`,
        });
      });
  }


  resetEditorText() {

    this.setState({
      editorText: this.scriptDefaultScript,
      displayNewScriptConfirmation: false,
      editorScriptName: ""
    });

  }


  /* 
    Actions handeling
   */
  handleEditorChange(value, event) {

    this.setState({ editorText: value });

  }

  /* 
    Validate methods
  */
  validationWindowForNewScript() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayNewScriptConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.resetEditorText()}
          autoFocus
        />
      </div>
    );
  }


  handleDeletedScript = () => {

    this.resetEditorText();

  };


  handleEditorDidMount(editor, monaco) {

    this.state.editorRef.current = editor;

  }

  selectQuestion = (questionSelected) => {

    this.state.editorRef.current?.trigger('keyboard', 'type', { text: 'var answer = responses.get("' + questionSelected + '");' });

  };

  render() {

    this.leftContents = (
      <React.Fragment>
        <h2 className="editorTitle">Script editor</h2>
        <div className="toolbarCenterRequirement">
          <InputText
            value={this.state.editorScriptName}
            onChange={(e) => this.setState({ editorScriptName: e.currentTarget.value })}

            placeholder="Script name"
            style={{ width: '20rem' }}
          />
        </div>
      </React.Fragment>
    );
    this.rightContents = (
      <div className="action">
        <Button
          type="button"
          icon="pi pi-download"
          tooltip="Download script"
          tooltipOptions={{ position: 'left' }}
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
          onClick={() => this.setState({
            downloadScript: true,
          }, () => {
            this.setState({
              downloadScript: false
            });
          })}
        />
        <Button
          tooltip="Save script"
          tooltipOptions={{ position: 'left' }}
          icon="pi pi-save"
          className="p-button-danger"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          onClick={this.handleOneUpload}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => this.setState({
            deleteScript: true,
          }, () => {
            this.setState({
              deleteScript: false
            });
          })}
        />
      </div>
    );

    return (
      <div className="editor-script">
        <div className="sideBarJS">
          <CodeSidebarJS
            scriptToViewCallback={this.refreshScript}
            reloadScripts={this.state.refreshScriptsState}
            resetRefreshScriptsState={() => this.setState({ refreshScriptsState: false })}
            deletedScript={this.handleDeletedScript}
            actionDeleteScript={this.state.deleteScript}
            actionDownloadScript={this.state.downloadScript}
          />
        </div>
        <Toolbar left={this.leftContents} right={this.rightContents}/>
        <div className="workPlace">
          <Card /*title={"Code editor JS" this.formService.t(this.t, "ui_admin_editor_js_title", false) }*/>
            <Toast ref={(el) => (this.toast = el)}/>
            <div className="p-grid" id="scriptEditorJS">
              <div className="p-col-12 p-d-flex p-jc-center" id="editorAreaJS">
                <Editor
                  height="100%"
                  defaultLanguage="javascript"
                  defaultValue={this.scriptDefaultScript}
                  value={this.state.editorText}
                  onChange={this.handleEditorChange}
                  onMount={this.handleEditorDidMount}
                />
              </div>


              <div className="p-col-12 p-d-flex p-jc-center" id="questionSelectorJS">
                <QuestionSelectorJS
                  questionSelected={this.selectQuestion}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

}

export default withTranslation()(CodeEditorJS);
