import {AxiosResponse} from 'axios';
import {APIService} from "../../shared/services/APIService";
import {DecisionTree} from "../../admin/models/DecisionTree";
import {Schema} from "../models/Schema";

export class DecisionTreeEditorService extends APIService {

    /**
     * save decision trees schema
     */
    save(files: File[]) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }
        return this.http.post<void>(`/editor-decision-trees/upload`, formData);
    }

    /**
     * Get many decision trees
     */
    getMany(): Promise<AxiosResponse<DecisionTree[]>> {
        return this.http.get<DecisionTree[]>('/editor-decision-trees');
    }

    /**
     * Download a decision tree by name
     */
    downloadOne(name: string) {
        return this.http.get<Blob>(`/editor-decision-trees/${name}/download`, {responseType: 'blob'});
    }

    /**
     * Upload many decision trees
     */
    uploadMany(files: File[]) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }
        return this.http.post<void>(`/editor-decision-trees/upload`, formData);
    }

    /**
     * Delete one decision tree
     */
    deleteOne(key: string) {
        return this.http.delete<void>(`/editor-decision-trees/${key}`);
    }

    saveSchema(schema: Schema): Promise<AxiosResponse<Schema>> {
        return this.http.put<Schema>('/editor-decision-trees/upload', schema);
    }

    getOne(key: string): Promise<AxiosResponse<Schema>> {
        return this.http.get<Schema>(`/editor-decision-trees/${key}`);
    }

    findAll(): Promise<AxiosResponse<Schema[]>> {
        return this.http.get<Schema[]>(`/editor-decision-trees`);
    }

    updateSchemaName(schema: Schema | null, currentTreeName: string | null) {
        return this.http.put<void>(`/editor-decision-trees/update-name/${currentTreeName}`, schema);
    }

    createSchema(datas: { name: any; key: any }): Promise<AxiosResponse<Schema>>  {
        return this.http.post<Schema>('/editor-decision-trees', datas);
    }

    getBetonJSON(schema: Schema): Promise<AxiosResponse<string>> {
        return this.http.get<string>(`/decision-trees/get-beton-json/${schema.key}`);
    }
}
