import { TFunction } from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormQuestion } from "../models/FormQuestion";
import { RichText } from "prismic-reactjs";
import { FormService } from "../services/FormService";
import "./FormInputNumber.scss";

interface Props extends WithTranslation {
  question: FormQuestion;
  value: any;
  onValueChange: (v: any) => void;
  onBlur: (question: string, v: any) => void;
  disabled?: boolean;
  parentQuestion?: FormQuestion;
  values?: any;
}

class FormInputNumber extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;

  state = {
    showValidationMessage: false,
    value: 0,
    modified: false
  };

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;

    this.state = {
      showValidationMessage: false,
      value: 0,
      modified: false
    };
  }

  componentDidMount() {
    this.setState({ showValidationMessage: false, value: this.resetValueIfInvalid() });

    this.bindOnBlur();
    this.bindKeyup();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.parentQuestion) {
      if ((this.props.parentQuestion.question !== prevProps.parentQuestion?.question) && !this.props.value) {
        this.bindOnBlur();
        this.bindKeyup();

        this.setState({ showValidationMessage: false, value: this.resetValueIfInvalid() });
      }
      else if ((this.props.parentQuestion.question !== prevProps.parentQuestion?.question) && this.props.value) {
        this.bindOnBlur();
        this.bindKeyup();

        this.setState({ showValidationMessage: false, value: this.props.value });
      }
    }
    else {
      if (this.props.question.question !== prevProps.question.question) {
        this.bindOnBlur();
        this.bindKeyup();

        this.setState({ showValidationMessage: false, value: this.resetValueIfInvalid(), modified: false });
      }
    }

    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  resetValueIfInvalid() {
    if (this.props.question.validation && Number.isInteger(this.props.question.validation.min) && (this.props.value < this.props.question.validation.min)) {
      return null;
    }
    else {
      return this.props.value;
    }
  }

  onValueChange(v: any) {
    this.setState({ ...this.state, value: v });
    this.setState({ modified: true });
    this.props.onValueChange(v);
  }

  private bindOnBlur() {
    document.getElementById(this.props.question.question)?.addEventListener('blur', () => {
      if (!this.state.showValidationMessage) {
        this.setState({ ...this.state, showValidationMessage: true });
      }
      this.props.onBlur(this.props.question.question, this.state.value);
    });
  }

  private bindKeyup() {
    document.getElementById(this.props.question.question)?.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === "enter") {
        this.setState({ ...this.state, showValidationMessage: true });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-group inputNumberForm">
          <div>
            <input
              type="number"
              pattern="[0-9]*"
              value={ this.state.value ?? 0 }
              id={ this.props.question.question }
              disabled={ this.props.disabled }
              step={ this.props.question.validation?.step ?? 1 }
              min={ this.props.question.validation?.min ?? -999999999999999 }
              max={ this.props.question.validation?.max ?? 999999999999999 }
              onChange={ (e: any) => {
                if (e.target.value.split('-').length > 1) {
                  this.onValueChange(0)
                }
                if (!this.state.modified && e.target.value[0] === '0') {
                  const modif = e.target.value.substring(1);
                  this.onValueChange(modif)
                }
                else {
                  this.onValueChange(e.target.value)
                }
              } }
              onKeyPress={ (event) => {
                if (!/[0-9,.-]/.test(event.key)) {
                  event.preventDefault();
                }
              } }

            />
          </div>
          { this.props.question.unit && (
            <>{ RichText.render(this.t(this.props.question.unit, { returnObjects: true })) }</>
          ) }
        </div>
        { this.props.question.validation && this.state.showValidationMessage && Number.isInteger(this.props.question.validation.min) && (this.state.value < this.props.question.validation.min) && (
          <div className="form-question-suggestion text-danger p-text-italic">
            { this.formService.checkIfValue(RichText.render(this.t("ui_input_min_value", { returnObjects: true })), "ui_input_min_value") }
            { this.props.question.validation.min }
            { this.props.question.unit && (
              <React.Fragment> { RichText.render(this.t(this.props.question.unit, { returnObjects: true })) }</React.Fragment>
            ) }
          </div>
        ) }
        { this.props.question.validation && this.state.showValidationMessage && Number.isInteger(this.props.question.validation.max) && (this.state.value > this.props.question.validation.max) && (
          <div className="form-question-suggestion text-danger p-text-italic">
            { this.formService.checkIfValue(RichText.render(this.t("ui_input_max_value", { returnObjects: true })), "ui_input_max_value") }
            { this.props.question.validation.max }
            { this.props.question.unit && (
              <React.Fragment> { RichText.render(this.t(this.props.question.unit, { returnObjects: true })) }</React.Fragment>
            ) }
          </div>
        ) }
      </React.Fragment>
    );
  }
}

export default withTranslation()(FormInputNumber);
