import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FormService } from '../../form/services/FormService';


interface Props extends WithTranslation {

}

class Logo extends Component<Props> {
  private t: TFunction;
  private formService: FormService;


  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  render() {
    return (
      <div>
            <span className="logo-full">
            <img className={"logo"} src={this.formService.t(this.t, `bbri_logo`, false, false, false, true)}
                 alt="BBRI logo"/>
            </span>
        <span className="logo-compact">
            <img className={"logo"} src={this.formService.t(this.t, `bbri_logo_small`, false, false, false, true)}
                 alt="BBRI logo"/>
            </span>
      </div>
    );
  }
}

export default withTranslation()(Logo);
