import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import AdminDecisionTrees from "./components/AdminDecisionTrees";
import AdminForm from "./components/AdminForm";
import AdminRequirements from "./components/AdminRequirements";
import AdminScripts from "./components/AdminScripts";
import AdminEditorJs from "./components/AdminEditorJs";
import AdminHtmls from "./components/AdminHTML";
import AdminEditorHtml from "./components/AdminEditorHtml";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import AdminSubmissions from './components/AdminSubmissions';
import AdminTranslations from "./components/AdminTranslations";
import AdminImages from "./components/AdminImages";
import AdminGraph from "./components/AdminGraph"
import AdminDiagram from "./components/AdminDiagram"
import AdminDiagramComment from "./components/AdminDiagramComment"
import {TabPanel, TabView} from "primereact/tabview";
import NavigateButton from "../shared/components/NavigateButton";
import {AxiosResponse} from "axios";
import {FileUtils} from "../shared/utils/FileUtils";
import {AdminService} from "./services/AdminService";
import {SeverityEnum} from "../shared/enum/SeverityEnum";
import {FileUpload} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import {FormService} from "../form/services/FormService";
import {TFunction} from "i18next";
import {VERSION} from "../shared/constants";


interface Props extends WithTranslation {
}

interface States {
  activeIndex: number
  activeIndexEditor: number
  backVersion: string
}

class AdminPage extends React.Component<Props, States> {
  private adminService : AdminService
  private formService : FormService
  private fileUpload: FileUpload | null;
  private t: TFunction;
  private toast: Toast | null;
  constructor(props: Props) {
    super(props);

    this.adminService = new AdminService()
    this.formService = new FormService()

    this.fileUpload = null;
    this.toast = null;
    this.t = this.props.t;

    this.state = {
      activeIndex: 0,
      activeIndexEditor: 0,
      backVersion: '',
    }
  }

  componentDidMount() {
    this.adminService.getVersion()
        .then(res => this.setState({
          backVersion: res.data
        }))
  }

  handleDownload() {
    this.adminService.downloadAll().then((response: AxiosResponse<Blob>) => {
      FileUtils.downloadFile(response);
    });
  }

  handleUpload(e: { files: File }) {
    if (!e.files) {
      return;
    }
    this.adminService
        .uploadAll(e.files[0])
        .then(() => {
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: this.formService.t(this.t, "ui_admin_script_upload_toast_success", false),
          });
          this.fileUpload?.clear();
        })
        .catch((err: any) => {
          const message = err?.response?.data?.message;
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: `${this.formService.t(this.t, "ui_admin_script_upload_toast_error", false)} : ${message}`,
          });
        });
  }
  render() {
    const hasLocalTranslations = process.env.hasOwnProperty('REACT_APP_LOCAL_TRANSLATIONS') ? process.env.REACT_APP_LOCAL_TRANSLATIONS === 'true' : false;

    return (
      <div className="container">
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="p-grid">
          <div className="p-col-12 p-md-6">
            <h1>Admin</h1>
            <p>Front version : {VERSION}</p>
            <p>Back version : {this.state.backVersion}</p>
          </div>
          <div className="p-col-12 p-md-6 p-d-flex p-jc-end p-ai-center">
            <NavigateButton header="ui_navigate_form_header" path="/" logo="pi-sign-out"></NavigateButton>
          </div>
          <div className="p-col-12 p-md-6">
            <Card title="Editor">
              <Button
                label="Access to editor"
                className="p-mb-2"
                onClick={ () => window.location.href = 'editor' }
              />
            </Card>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminSubmissions/>
          </div>
          {
            hasLocalTranslations &&
              <React.Fragment>
                  <div className="p-col-12 p-md-6">
                      <AdminTranslations/>
                  </div>
                  <div className="p-col-12 p-md-6">
                      <AdminImages/>
                  </div>
              </React.Fragment>
          }
          <div className="p-col-12">
            <Card title="File">
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                    label="Dowload db"
                    className="p-mb-2"
                    onClick={ () => this.handleDownload() }
                />
                <FileUpload
                    mode="basic"
                    ref={(el) => (this.fileUpload = el)}
                    accept="application/x-zip-compressed"
                    customUpload={true}
                    uploadHandler={(e: { files: File }) => this.handleUpload(e)}
                    chooseLabel={this.formService.t(this.t, "ui_upload_db", false)}
                    // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_script_upload_content")}</p>}
                ></FileUpload>
              </div>
              <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                <TabPanel header="Form">
                  <AdminForm/>
                </TabPanel>
                <TabPanel header="Requirements">
                  <AdminRequirements/>
                </TabPanel>
                <TabPanel header="Decision trees">
                  <AdminDecisionTrees/>
                </TabPanel>
                <TabPanel header="Scripts">
                  <AdminScripts/>
                </TabPanel>
                <TabPanel header="HTML template">
                  <AdminHtmls/>
                </TabPanel>
                <TabPanel header="Graphs">
                  <AdminGraph/>
                </TabPanel>
                <TabPanel header="Diagram">
                  <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                      <AdminDiagram/>
                    </div>
                    <div className="p-col-12 p-md-6">
                      <AdminDiagramComment/>
                    </div>
                  </div>
                </TabPanel>
                {/*{*/}
                {/*    hasLocalTranslations &&*/}
                {/*      <TabPanel header="Translation">*/}
                {/*        <AdminTranslations/>*/}
                {/*      </TabPanel>*/}
                {/*}*/}
                {/*{*/}
                {/*    hasLocalTranslations &&*/}
                {/*      <TabPanel header="Images">*/}
                {/*        <AdminImages/>*/}
                {/*      </TabPanel>*/}
                {/*}*/}
              </TabView>
            </Card>
          </div>
          <div className="p-col-12">
            <Card title="Editor">
              <TabView activeIndex={this.state.activeIndexEditor} onTabChange={(e) => this.setState({ activeIndexEditor: e.index })}>
                <TabPanel header="Editor JS">
                  <AdminEditorJs/>
                </TabPanel>
                <TabPanel header="Editor HTML">
                  <AdminEditorHtml/>
                </TabPanel>
              </TabView>
            </Card>
          </div>
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminForm/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminRequirements/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminDecisionTrees/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminScripts/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminEditorJs/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminEditorHtml/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminHtmls/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminGraph/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminDiagram/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-6">*/}
          {/*  <AdminDiagramComment/>*/}
          {/*</div>*/}
          {/*<div className="p-col-12 p-md-12">*/}
          {/*  <AdminEditorDiagram/>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}

export default withTranslation()(AdminPage);
