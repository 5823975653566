import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthService } from '../services/AuthService';

interface Props {
  path: string;
}

export default class PrivateRoute extends React.Component<Props> {

  render() {
    return (
      <Route path={this.props.path} render={({ location }) => AuthService.isAuth
        ? this.props.children
        : <Redirect to={{ pathname: '/home', state: { from: location } }}/>}/>
    );
  }
}
