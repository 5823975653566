export class Requirement {

  constructor(
    readonly group: string,
    readonly requirement: string,
    readonly requirementTitle: string,    
    readonly answer: any,
    readonly prefix?: string,
    readonly suffix?: string,    
    readonly uppercase?: boolean,
    readonly isActive?: boolean,
  ) {
  }
}
