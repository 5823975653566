import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Diagram } from "../models/Diagram"
import { DiagramDot } from "../models/DiagramDot";
import { FormService } from "../services/FormService";
import { TFunction } from "i18next";
import "./FormDiagram.scss";
import { DiagramComment } from "../models/DiagramComment";
import Draggable, { DraggableData } from "react-draggable";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { DiagramLegend } from "../models/DiagramLegend";

interface Props extends WithTranslation {
    diagram: Diagram
    comments: DiagramComment[]
    editable: boolean
    allPrismicKeys?: string[]
    mouseUp?: (diagramDots: DiagramDot[], width: number, height: number) => void
    onCommentSave?: (comment: DiagramComment) => void
    onCommentDelete?: (number: number) => void
    onLegendSave?: (legend: DiagramLegend) => void
    onLegendDelete ?: (title: string) => void
}

interface States {
    dots: DiagramDot[]
    currentComment: DiagramComment
    currentDot: DiagramDot | null
    searchedComment: string
    imageSize: { width: number, height: number }
    showModal: boolean
    previousComments: DiagramComment[]
    newLegend : DiagramLegend
}

class FormDiagram extends React.Component<Props, States> {

    private readonly fService: FormService
    private readonly t: TFunction
    private toast: Toast | null;
    private imgRef: React.RefObject<HTMLImageElement>

    constructor(props: Props) {
        super(props)
        this.t = this.props.t
        this.fService = new FormService()
        this.toast = null;
        this.imgRef = React.createRef()

        let initialComment: DiagramComment
        if (props.comments.length > 0) {
            initialComment = this.props.comments[0]
        }
        else {
            initialComment = { number: 1, title: "", relatedComments: [], content: "" }
        }

        this.state = {
            dots: props.diagram.dots,
            imageSize: { width: 0, height: 0 },
            showModal: false,
            currentComment: initialComment,
            currentDot: null,
            searchedComment: "",
            previousComments: [],
            newLegend: {
                title: "",
                image: ""
            }
        }
    }

    componentDidMount(): void {
        window.addEventListener("resize", () => {
            this.handleResize()
        })
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        this.setState({
            dots: nextProps.diagram.dots,
        })
    }

    handleImageLoad(e): void {
        this.setState({
            imageSize: {
                width: e.target.width,
                height: e.target.height
            }
        })
    }

    handleResize() {
        if (this.imgRef.current) {

            const { width, height } = this.imgRef.current

            if (this.state.imageSize.width !== width
                || this.state.imageSize.height !== height) {
                this.setState({
                    imageSize: {
                        width: width,
                        height: height
                    }
                })
            }
        }
    }

    handleDrag(data: DraggableData, dot: DiagramDot) {
        let newDots = this.state.dots.map(
            d => d === dot ? { ...d, x: data.x, y: data.y } : d
        )
        this.setState({
            dots: newDots
        })
    }

    private _handleOnCommentClick(dots: DiagramDot[], comment: DiagramComment, dot: DiagramDot) {
        if (this.props.mouseUp && this.imgRef.current) {
            const newDots = dots.map((d => ({ ...d, x: d.x * (this.state.imageSize.width / this.props.diagram.imgSize.width), y: d.y * (this.state.imageSize.height / this.props.diagram.imgSize.height) })))
            this.props.mouseUp(newDots, this.imgRef.current.width, this.imgRef.current.height)
        }
        this.setState({
            currentComment: comment,
            currentDot: dot
        })
    }

    private _getComments() {
        const result: JSX.Element[] = []
        let filteredComment = this.props.comments
        if (this.state.searchedComment !== "") {
            filteredComment = this.props.comments.filter(c => c.number == parseInt(this.state.searchedComment))
        }
        filteredComment = filteredComment.sort((a, b) => a.number - b.number)

        for (const comment of filteredComment) {
            result.push((
                <div className="dot-container"
                    onClick={() => {

                        const dots = this.state.dots
                        const dot = { number: comment.number, x: 0, y: 0 }
                        dots.push(dot)

                        this._handleOnCommentClick(dots, comment, dot)
                    }}>
                    <div className="dot"
                        style={{ minWidth: "35px", minHeight: "35px" }}
                    >{comment.number}</div>{this.fService.t(this.t, comment.title, false)}
                </div>
            ))
        }


        return result
    }

    private _getDots() {

        const result: JSX.Element[] = []
        this.state.dots.forEach((dot, i) => {
            const comment = this.props.comments.find(e => e.number === dot.number)
            if (comment) result.push((
                <Draggable key={i}
                    position={{ x: dot.x * (this.state.imageSize.width / this.props.diagram.imgSize.width), y: dot.y * (this.state.imageSize.height / this.props.diagram.imgSize.height) }}
                    onDrag={(event, data) => {
                        this.handleDrag(data, dot)
                    }}
                    onStart={() => this._handleOnCommentClick(this.state.dots, comment, dot)}
                    onStop={(event, data) => {
                        this.handleDrag(data, dot)
                        this._handleOnCommentClick(this.state.dots, comment, dot)
                    }}
                    disabled={!this.props.editable}
                >
                    <div
                        className="dot-container"
                        onClick={() => {
                            this.setState({
                                showModal: true,
                                currentComment: comment,
                                currentDot: dot
                            })
                        }}
                        style={{
                            position: "absolute",
                            zIndex: 1
                        }}>
                        <div
                            style={{ width: this.state.imageSize.width / 25, height: this.state.imageSize.width / 25 }}
                            className={this.state.currentDot === dot && this.props.editable ? "selected-dot" : "dot"}
                        >{comment.number}</div>
                    </div>
                </Draggable>
            ))
        })

        return result

    }

    private _getModalHeader = (title: string) => {
        return (
            <>

                <div style={{ display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center" }}>
                    {this.state.previousComments.length > 0 && (
                        <Button style={{ marginRight: 20, border: "none" }}
                            icon="pi pi-angle-left"
                            className="p-button-rounded p-button-secondary p-button-text"
                            onClick={() => {
                                const lastComment = this.state.previousComments[this.state.previousComments.length - 1]
                                if (lastComment) {
                                    const newPreviousComments = [...this.state.previousComments]
                                    newPreviousComments.splice(newPreviousComments.length - 1, 1)
                                    this.setState({
                                        currentComment: lastComment,
                                        previousComments: newPreviousComments
                                    })
                                }
                            }} />
                    )}
                    <p>{title}</p>
                </div>

            </>
        )
    }

    render() {
        return (
            <div className="diagram-container">
                <Toast ref={(el) => (this.toast = el)} />
                <div className="p-d-flex">
                    {this.props.diagram.name !== "" && (
                        <>
                            <div
                                className={this.props.editable ? "p-col-12 p-md-9" : "p-col-12 p-md-12"}
                            >

                                {this._getDots()}
                                <img
                                    style={{ width: "100%" }}
                                    src={this.fService.t(this.t, this.props.diagram.imageName, false, false, false, true)}
                                    onLoad={(e) => this.handleImageLoad(e)}
                                    ref={this.imgRef}
                                ></img>

                            </div>
                            {this.props.editable && (
                                <div className={this.props.editable ? "p-col-12 p-md-3" : ""}>
                                    <Button className="p-button-danger"
                                        onClick={() => {
                                            const newDots = this.state.dots.filter(dot => dot !== this.state.currentDot)
                                            this.setState({
                                                dots: newDots
                                            })
                                            if (this.props.mouseUp) {
                                                this.props.mouseUp(newDots, this.state.imageSize.width, this.state.imageSize.height)
                                            }
                                        }}
                                    >Remove selected dot</Button>
                                    <InputText value={this.state.searchedComment}
                                        onChange={(e) => this.setState({ searchedComment: e.currentTarget.value })}
                                        style={{ marginTop: "10px", marginBottom: "10px" }}
                                        type="number"
                                        placeholder="Search comment" />
                                    <div
                                        className="comment-container"
                                    >
                                        {this._getComments()}
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                </div>

                {!this.props.editable && (
                
                    <div className="legend-container">
                        {this.props.diagram.legends.map((l, i) => (
                            <div>
                                <img style={{ width: "20px", height: "20px"}} src={this.fService.t(this.t, l.image, false, false, false, true)}></img>
                                <p>{this.fService.t(this.t, l.title, false)}</p>
                                {i !== this.props.diagram.legends.length - 1 && <p>-</p>}
                            </div>
                        ))}
                    </div>
                    
                )}

                {this.props.editable ? (
                    <>
                        <div className="legend-editor-container">
                            <h4>Legend</h4>
                            {this.props.diagram.legends.map(l => (
                                <div style={{ marginBottom: "10px" }}>
                                    <img style={{ height: "20px", width: "20px"}} src={this.fService.t(this.t, l.image, false, false, false, true)}></img>
                                    <p className="p-col-12 p-md-3">{l.title}</p>
                                    <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger"
                                    onClick={(e) => {
                                        if (this.props.onLegendDelete) {
                                            this.props.onLegendDelete(l.title)
                                        }
                                    }}
                                    ></Button>
                                </div>
                            ))}
                            <div>
                                <Dropdown
                                    value={this.state.newLegend.image}
                                    options={this.props.allPrismicKeys}
                                    onChange={(e) => {
                                        this.setState({
                                            newLegend: {...this.state.newLegend, image: e.target.value}
                                        })
                                    }}
                                    placeholder="New prismic key legend image"
                                    editable
                                />
                                <InputText 
                                    placeholder="New prismic key legend title"
                                    value={this.state.newLegend.title}
                                    onChange={(e) => {
                                        this.setState({
                                            newLegend: {...this.state.newLegend, title: e.currentTarget.value}
                                        })
                                    }}
                                ></InputText>
                                <Button
                                    icon="pi pi-plus"
                                    className="p-button-danger"
                                    style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                                    onClick={(e) => {
                                        if (this.props.onLegendSave) {
                                            this.props.onLegendSave(this.state.newLegend)
                                        }
                                    }}
                                ></Button>
                            </div>
                        </div>
                        <div className="comment-editor-container">
                            <h4>Comments</h4>
                            <div>
                                <Dropdown
                                    value={this.state.currentComment.number}
                                    options={this.props.comments.map(e => e.number)}
                                    onChange={(e) => {
                                        if (parseInt(e.value)) {
                                            const newComment = this.props.comments.find(c => c.number == e.value)
                                            if (newComment) {
                                                this.setState({
                                                    currentComment: newComment
                                                })
                                            }
                                            else {
                                                this.setState({
                                                    currentComment: {...this.state.currentComment, number: parseInt(e.value)}
                                                })
                                            }
                                        }
                                    }}
                                    placeholder="Comment number"
                                    editable
                                />
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <label >Prismic key for comment name : </label>
                                <Dropdown className="p-col-12 p-md-4"
                                    value={this.state.currentComment.title}
                                    options={this.props.allPrismicKeys}
                                    onChange={(e) => {
                                        this.setState({
                                            currentComment: { ...this.state.currentComment, title: e.value }
                                        })
                                    }}
                                    editable
                                />
                            </div>
                            <div>
                                <label>Prismic key for comment description : </label>
                                <Dropdown className="p-col-12 p-md-4"
                                    style={{ marginBottom: 10 }}
                                    value={this.state.currentComment.content}
                                    options={this.props.allPrismicKeys}
                                    onChange={(e) => {
                                        this.setState({
                                            currentComment: { ...this.state.currentComment, content: e.value }
                                        })
                                    }}
                                    editable
                                />
                            </div>
                            <div>
                                <label>Related comments : </label>
                                <MultiSelect className="p-col-12 p-md-4"
                                    style={{ marginBottom: 10 }}
                                    value={this.state.currentComment.relatedComments}
                                    options={this.props.comments.map(c => c.number)}
                                    onChange={(e) => {
                                        this.setState({
                                            currentComment: { ...this.state.currentComment, relatedComments: e.value }
                                        })
                                    }}
                                />
                            </div>
                            <div>
                                <Button
                                    onClick={() => {
                                        if (this.props.onCommentSave) {
                                            this.props.onCommentSave(this.state.currentComment)
                                        }
                                    }}
                                >Save comment</Button>
                                <Button
                                    className="p-ml-2"
                                    onClick={() => {
                                        let newNumber
                                        if (this.props.comments.length > 0) {
                                            newNumber = this.props.comments[this.props.comments.length - 1].number + 1
                                        }
                                        else {
                                            newNumber = 1
                                        }
                                        this.setState({
                                            currentComment: { number: newNumber, title: "", relatedComments: [], content: "" }
                                        })
                                    }}
                                >New comment</Button>
                                <Button
                                    className="p-ml-2 p-button-danger"
                                    onClick={() => {
                                        if (this.props.onCommentDelete) {
                                            this.props.onCommentDelete(this.state.currentComment.number)
                                        }
                                    }}
                                >Delete this comment</Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        {this.state.currentComment && (
                            <Dialog
                                visible={this.state.showModal}
                                onHide={() => this.setState({ showModal: false, previousComments: [] })}
                                style={{ width: "800px" }}
                                header={this._getModalHeader(`${this.state.currentComment.number} - ${this.fService.t(this.t, this.state.currentComment.title, false)}`)}
                            >
                                <div>
                                    <p style={{ marginBottom: 10 }}>{this.fService.t(this.t, this.state.currentComment.content, true)}</p>
                                    <p><strong>Related comments : </strong></p>
                                    {this.state.currentComment.relatedComments.map(c => {
                                        const comment = this.props.comments.find(com => com.number === c)
                                        return (
                                            <div
                                                className="dot-container"
                                                onClick={() => {
                                                    if (comment) {
                                                        const previousComment = this.state.currentComment
                                                        const newPreviousComments = [...this.state.previousComments]
                                                        newPreviousComments.push(previousComment)
                                                        this.setState({
                                                            previousComments: newPreviousComments,
                                                            currentComment: comment
                                                        })
                                                    }
                                                }}>
                                                <div className="dot"
                                                    style={{ minWidth: "25px", minHeight: "25px" }}
                                                >{comment?.number}</div>{comment && this.fService.t(this.t, comment.title, false)}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Dialog>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default withTranslation()(FormDiagram)