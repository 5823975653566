import "./import-jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import draw2d from "draw2d";
import {Transition} from "./shapes/transition";
import {Toast} from "primereact/toast";
import {NodeAnswerList} from "./shapes/node-answer-list";
import canvas from "./canvas";
import {NodeAnswerBool} from "./shapes/node-answer-bool";
import {NodeRange} from "./shapes/node-range";
import {NodeComputation} from "./shapes/node-computation";
import {NodeQuestionResult} from "./shapes/node-question-result";
import {NodeResult} from "./shapes/node-result";
import {NodeMin} from "./shapes/node-min";
import {NodeMax} from "./shapes/node-max";
import {TransitionLHS} from "./shapes/transition-lhs";
import {TransitionRHS} from "./shapes/transition-rhs";
import {NodeScript} from "./shapes/node-script";
import {NodeConcat} from "./shapes/node-concat";
import {NodeMultipleAnswerList} from "./shapes/node-multiple-answer-list";
import {TransitionConcat} from "./shapes/transition-concat";

export class Reader extends draw2d.io.json.Reader {

  canvas: any

  constructor(canvas ?: any) {
    super();
    this.canvas = canvas;
  }

  loadJSONAndSchema(canvas: any, json: string) {
    super.unmarshal(canvas, json);
  }

  /**
   * Override of the decision-tree method -> This method tells decision-tree to use our custom classes for given figures.
   * @param {string} type - the NAME of the figure (see ./enums/shape-names.enum.ts)
   * @returns - the created figure.
   */
  createFigureFromType(type: string) {
    switch (type) {
      case 'Transition':
        return new Transition();
      case 'node':
        return new NodeAnswerList();
      case 'node_answer_bool':
        return new NodeAnswerBool();
      case 'node_answer_list':
        return new NodeAnswerList();
      case 'node_min':
        return new NodeMin();
      case 'node_max':
        return new NodeMax();
      case 'node_range':
        return new NodeRange();
      case 'node_computation':
        return new NodeComputation();
      case 'node_question_result':
        return new NodeQuestionResult();
      case 'node_result':
        return new NodeResult();
      case 'lhs':
        return new TransitionLHS();
      case 'rhs':
        return new TransitionRHS();
      case 'node_script':
        return new NodeScript();
      case 'node_concat':
        return new NodeConcat();
      case 'node_multiple_answer_list':
        return new NodeMultipleAnswerList();
      case 'transition_concat':
        return new TransitionConcat();
      default:
        return super.createFigureFromType(type);
    }
  }

}
