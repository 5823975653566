import React, { Component } from "react";
import Logo from "../shared/components/Logo";
import ToolLogo from "../shared/components/ToolLogo";
import SelectLanguage from "../shared/components/SelectLanguage";
import "./MainLayout.scss";
import About from '../shared/components/About';
import NavigateButton from "../shared/components/NavigateButton";
import {AuthService} from "../login/services/AuthService";
import {RoleEnum} from "../shared/enum/RoleEnum";

interface Props {
}

class MainLayout extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <header className="layout-header">
          <Logo/>
          <ToolLogo/>
          <div className="right">
            {
                AuthService.user?.role === RoleEnum.ADMIN && (
                    <NavigateButton header="ui_navigate_admin_header" logo="pi-cog" path="/admin"></NavigateButton>
                )
            }
            <About/>
            <SelectLanguage/>
          </div>
        </header>
        <main className="layout-content container">{this.props.children}</main>
      </React.Fragment>
    );
  }
}

export default MainLayout;
