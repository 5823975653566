import { Button } from "@bbri/ui";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { RoleEnum } from "../../shared/enum/RoleEnum";
import { AuthService } from "../services/AuthService";
import { LoginDTO } from "../dto/LoginDTO";
import { AuthUser } from "../models/AuthUser";
import { FormService } from '../../form/services/FormService';

interface Props extends WithTranslation {
  email: string;
  password: string;
  onLogin: (isAuth: boolean) => void;
}

function ButtonLogin(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const authService = new AuthService();
  const formService = new FormService();

  const handleClick = () => {
    const dto = new LoginDTO(props.email?.trim().toLowerCase(), props.password);

    authService.login(dto).then((user: AuthUser | null) => {
      if (!user) {
        props.onLogin(false);
        return;
      }

      props.onLogin(true);

      let from = { pathname: "/" };

      if (location.state) {
        from = (location.state as any).from;
      }

      // = Admin ?
      if (user.role === RoleEnum.ADMIN) {
        from = { pathname: "/admin" };
      }

      history.replace(from);
    });
  };

  return (
    <Button block type="button" onClick={() => {
      handleClick();
    }}>
      {formService.t(props.t, "button_login", false, true)}
    </Button>
  );
}

export default withTranslation()(ButtonLogin);
