import { APIService } from '../../shared/services/APIService';

export class AdminGraphService extends APIService {

  /**
   * Upload and replace the form
   *
   * @role Admin
   */
  uploadManyGraph(file: File) {
    const formData = new FormData();
    formData.append('graphs', file);
    return this.http.put<void>('/graph/uploadmany', formData);
  }

  

}