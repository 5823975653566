import React, { ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip, ReferenceLine, ResponsiveContainer, Text, ReferenceDot } from 'recharts';
import { Dot } from "../models/Dot"

interface Props extends WithTranslation {
    dots: Dot[],
    legend: string
}

interface States {
    highlightedDot : Dot[]
    activeHighlightedDot : number
}

class FormPVGraph extends React.Component<Props, States> {
    
    constructor(props: Props) {
        super(props)
        this.state = {
            highlightedDot: [],
            activeHighlightedDot: 0
        }
    }

    handleOnMouseEnter(i) {
        if (i !== this.state.highlightedDot.length - 1) {
            const newList = [...this.state.highlightedDot]
            const temp = newList[newList.length - 1]
            newList[newList.length - 1] = newList[i]
            newList[i] = temp
            this.setState({
                highlightedDot: newList
            })
        }
    }

    _renderGraph() {
        return (
            <LineChart data={this.props.dots} margin={{ top: 10, right: 10, bottom: 10, left: 10 }} >
                
                <XAxis type="number" dataKey="x" 
                    domain={["dataMin", "dataMax"]}
                    label={{ value: "Volume (L)", position: "insideBottomRight", dy: 10}}/>
                <YAxis type="number"
                    label={{ value: "Puissance (W)", position: "insideLeft", angle: -90,   dy: -10}} />
                {/* <Legend /> */}
                <Tooltip
                    cursor={false}
                    // wrapperStyle={{ top: 100, left: 300 }}
                    // position={{ y:150 }}
                    // coordinate={{ x: 100, y: 500 }}
                    formatter={(value) => parseInt(value.toString()) + " W"}
                    labelFormatter={(value) => value + " L"}/>
                <Line type="monotone" 
                    name={this.props.legend}
                    dataKey="y" 
                    isAnimationActive={false}
                    stroke="#01A398" 
                    strokeWidth={3}
                    dot={false}
                    activeDot={{
                        r: 5,
                        onClick: (c, event) => {
                            this.setState(state => {
                                const dotAlreadyHighlighted = this.state.highlightedDot.find(d => d.x === event["payload"]["x"])
                                let newList : Dot[] = []
                                if (!dotAlreadyHighlighted) {
                                    newList = state.highlightedDot.concat({x: event["payload"]["x"], y: Math.floor(event["payload"]["y"] * 100)/100})
                                }
                                this.setState({
                                    highlightedDot: newList
                                })
                            })
                        },
                        
                    }}
                    />   
                {this.state.highlightedDot.map(
                    (coord, i) => (
                        <React.Fragment key={coord.x}>
                            <ReferenceDot x={coord.x} y={coord.y} 
                                r={5}
                                onClick={() => {
                                    const newList = this.state.highlightedDot.filter(d => d.x !== coord.x)
                                    this.setState({
                                        highlightedDot: newList
                                    })
                                }}
                                onMouseEnter={() => {
                                    this.handleOnMouseEnter(i)
                                }}
                                label={(props) => (<g>
                                    <rect
                                      style={{ strokeWidth: 1, stroke: "#01A398" }}
                                      x={props.viewBox.x + 10}
                                      y={props.viewBox.y - 10}
                                      fill="#A2D9DB"
                                      width={180}
                                      height={30}
                                      onMouseEnter={() => this.handleOnMouseEnter(i)}
                                    />
                                    <text x={props.viewBox.x} y={props.viewBox.y} fill="white" dy={10} dx={20}>
                                      {coord.x} L / {coord.y} W
                                    </text>
                                  </g>)}
                                isFront={true}></ReferenceDot>
                            <ReferenceLine segment={[{x : this.props.dots[0].x, y: coord.y}, {x : coord.x, y: coord.y}]} 
                                stroke="#A2D9DB" 
                                strokeDasharray="3 3"/>
                            <ReferenceLine segment={[{x : coord.x, y: 0}, {x : coord.x, y: coord.y}]}
                                stroke="#A2D9DB" 
                                strokeDasharray="3 3"/>
                        </React.Fragment>
                    )
                )}    
            </LineChart>
        )
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                <ResponsiveContainer width="95%" height={500}>
                    {this._renderGraph()}
                </ResponsiveContainer>
            </div>
        )
    }
}

export default withTranslation()(FormPVGraph)