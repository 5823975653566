import draw2d from "draw2d";
import {NODE_LIST} from "../enums/shape";

const draw2d2 = draw2d as any;

export class NodeMax extends draw2d2.SVGFigure {

    NAME = NODE_LIST.nodeMax.name;

    nodeLabel: any;
    label: any;


    constructor(x = 0, y = 0, name = '') {
        super({
            x,
            y,
            radius: 25,
            width: 250,
            height: 40,
            resizeable: false,
            bgColor: NODE_LIST.nodeMax.shapeBackgroundColor,
            userData: {
                name: NODE_LIST.nodeMax.defaultLabel,
                nodes: [],
                decisionTrees: [],
                scripts: [],
            }
        });

        this.label = new draw2d.shape.basic.Label({
            text: this.userData.name,
            resizeable: false,
            stroke: 0,
            selectable: false
        });
        this.add(this.label, new draw2d.layout.locator.CenterLocator());
        const circle = new draw2d.shape.basic.Circle({
            bgColor: NODE_LIST.nodeMax.iconBackgroundColor,
            width: 50,
            height: 50,
        });
        var image = new draw2d.shape.basic.Image({
            path: `https://img.icons8.com/${NODE_LIST.nodeMax.iconStyle}/96/${NODE_LIST.nodeMax.iconColor}/${NODE_LIST.nodeMax.icon}.png`,
            height: 35,
            width: 35
        });
        circle.add(image, new draw2d.layout.locator.CenterLocator());
        this.add(circle, new draw2d.layout.locator.XYRelPortLocator(0,-10));


        //Change port location
        this.outputPorts.data = [];
        const upPort = new draw2d.HybridPort();
        upPort.setName('up');
        const downPort = new draw2d.HybridPort();
        downPort.setName('down');
        this.addPort(upPort, new draw2d.layout.locator.TopLocator());
        this.addPort(downPort, new draw2d.layout.locator.BottomLocator());
    }

    setLabel(label: string): void {
        let text = `${label}`;

        this.label.attr({
            text: text,
            fontColor: '#000000', bold: false
        });
    }
}

