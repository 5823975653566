import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Diagram } from "../../../form/models/Diagram";
import './DiagramEditorSideBar.scss';

interface Props extends WithTranslation {
    allDiagrams: Diagram[]
    prismicKeys: string[]
    onDiagramSelection : (diagram: Diagram) => void
    onNewDiagram: (newDiagramName: string, newDiagramImage: string) => void
}

interface States {
    currentDiagram: Diagram
    displayNewDiagramConfirmation : boolean
    newDiagramName: string
    newDiagramImage: string
}

class DiagramEditorSideBar extends React.Component<Props, States> {

    constructor(props: Props) {
        super(props);
        this.state = {
            currentDiagram: props.allDiagrams[0],
            displayNewDiagramConfirmation: false,
            newDiagramImage: "",
            newDiagramName: ""
        }
    }

    actionBodyTemplate() {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    tooltip="Create new diagram"
                    /* label="New script"  */
                    icon="pi pi-plus"
                    style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                    className="p-button-success"
                    onClick={() =>
                        this.setState({ displayNewDiagramConfirmation: true })
                        //this.props.scriptToViewCallback(this.state.selectedRequirement.fileName);

                    }
                />
            </React.Fragment>
        );
    }

    validationWindowForNewScript() {
        return (
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
              onClick={() => this.setState({ displayNewDiagramConfirmation: false })}
              className="p-button-danger"
            />
            <Button
              label="Save"
              className="p-button-danger"
              style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
              icon="pi pi-check"
              onClick={() => this.handleNewDiagram()}
              autoFocus
            />
          </div>
        );
    }

    handleNewDiagram() {
        this.setState({ displayNewDiagramConfirmation: false })
        this.props.onNewDiagram(this.state.newDiagramName, this.state.newDiagramImage)
    }

    render() {
        return (
            <>
                <DataTable value={this.props.allDiagrams}
                    id="p-datatable-scrollable-body"
                    selection={this.state.currentDiagram}
                    onSelectionChange={e => {
                        this.setState({ currentDiagram: e.value });
                        if (this.props.onDiagramSelection) {
                            this.props.onDiagramSelection(e.value);
                        }
                    }}
                    selectionMode="single"
                    dataKey="name"
                    scrollHeight="70vh"
                    scrollable
                >
                    <Column key="name" columnKey="name" field="name" style={{ width: '50px' }} header="List of schemas" />
                </DataTable>
                <Toolbar
                    left={() =>
                        this.actionBodyTemplate()
                    }
                />
                <div>
                    <Dialog
                        header="Confirmation"
                        visible={this.state.displayNewDiagramConfirmation}
                        modal
                        style={{ width: "500px" }}
                        footer={this.validationWindowForNewScript()}
                        onHide={() => this.setState({ displayNewDiagramConfirmation: false })}
                    >
                        <div>
                            <div className="p-d-flex">
                                <label className="p-col-12 p-md-4">Name </label>
                                <InputText 
                                    className="p-col-12 p-md-8"
                                    style= {{ minWidth: 300 }}
                                    value={this.state.newDiagramName}
                                    onChange={(e) => this.setState({ newDiagramName: e.currentTarget.value})}
                                    placeholder="Schema name"></InputText>
                            </div>
                            <div className="p-d-flex">
                                <label className="p-col-12 p-md-4">Image </label>
                                <Dropdown style= {{ padding: 0, minWidth: 300 }}
                                    appendTo={document.body}
                                    className="p-col-12 p-md-8"
                                    value={this.state.newDiagramImage}
                                    options={this.props.prismicKeys}
                                    onChange={(e) => this.setState({ newDiagramImage: e.value})}
                                    placeholder="Schema image"
                                    editable
                                    ></Dropdown>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </>
        );
    }
}

export default withTranslation()(DiagramEditorSideBar);
