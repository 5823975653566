import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { TFunction } from "i18next";
import { Button } from "primereact/components/button/Button";
import "./topbar.scss";
import draw2d from "draw2d";
import { FormService } from "../../../form/services/FormService";
import { DecisionTreeEditorService } from "../../services/DecisionTreeEditorService";
import { SeverityEnum } from "../../../shared/enum/SeverityEnum";
import { Toast } from "primereact/toast";
import { Schema } from "../../models/Schema";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Input } from "@bbri/ui";
import { InputText } from "primereact/inputtext";

interface States {
  displayDialog: boolean;
  schema: Schema | null;
  currentTreeName: any;
  currentKeyName: string | null;
  showConfirmDeleteDialog: boolean;
  showJSONDialog: boolean;
  currentZoom: number;
  currentBetonJSON: string | null;
  lockSelect: boolean;
}

interface Props extends WithTranslation {
  view: any;
  schema: Schema | null;
  refreshSchemas: (newSchemaName: string, key: string) => void;
  setChanges: (changes: boolean) => void;
  changes: boolean;
  hideSidebar: () => void;
  resetSidebar: () => void;
  currenToolsName: string;
  setCurrentToolsName: (currentToolsName: string) => void;
  canvas: any;
  changeSchemaName: (name) => void;
  refreshSchemasList: () => void;
  lockSelect: (lockSelect: boolean) => void;
}

class Topbar extends React.Component<Props, States> {

  private t: TFunction;
  private formService: FormService;
  private decisionTreeEditorService: DecisionTreeEditorService;
  private toast: Toast | null;
  private leftContents: any;
  private rightContents: any;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.decisionTreeEditorService = new DecisionTreeEditorService();
    this.t = this.props.t;
    this.toast = null;
    this.state = {
      displayDialog: false,
      schema: this.props.schema,
      currentTreeName: null,
      currentKeyName: null,
      showConfirmDeleteDialog: false,
      showJSONDialog: false,
      currentZoom: 1.5,
      currentBetonJSON: null,
      lockSelect: false

    };
  }

  saveSchema() {
    let nbrRootNodes = 0;
    for (const figure of this.props.canvas.figures.data) {
      if (figure.userData.root) {
        nbrRootNodes++;
      }
    }
    if (nbrRootNodes > 1) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `The schema has more than one root node!`,
      });
      return;
    }
    const writer = new draw2d.io.json.Writer();
    writer.marshal(this.props.view, json => {
      if (this.props.schema) {
        let hasRootNode = false;
        for (const figure of this.props.canvas.figures.data) {
          if (figure.userData.root) {
            hasRootNode = true;
          }
        }
        if (!hasRootNode) {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: `The schema doesn't have a root node!`,
          });
          return;
        }

        const schema = new Schema(this.props.schema.key, this.props.schema.name, JSON.stringify(json));
        this.decisionTreeEditorService.saveSchema(schema).then(() => {
          this.props.setChanges(false);
          this.props.refreshSchemasList();
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: 'Schema saved',
          });
        }).catch((e) => {
          if (e.response.status === 400) {
            this.toast?.show({
              severity: SeverityEnum.WARN,
              detail: `Schema has been saved but decision tree is invalid: ${e.response.data.error ?? '/'} `,
            });
            this.props.setChanges(false);
          }
          else {
            this.toast?.show({
              severity: SeverityEnum.ERROR,
              detail: `Error occurred while saving schema: ${e.response.data.error?.message ?? 'Error not communicated'}`,
            });
          }
        });
      }
      else {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: 'Select a schema first !',
        });
      }
    });
  }

  getBetonJSON() {
    this.props.hideSidebar();
    const writer = new draw2d.io.json.Writer();
    writer.marshal(this.props.view, json => {
      if (this.props.schema) {
        const schema = new Schema(this.props.schema.key, this.props.schema.name, JSON.stringify(json));
        this.decisionTreeEditorService.getBetonJSON(schema).then((res: any) => {
          this.setState({
            showJSONDialog: true,
            currentBetonJSON: JSON.stringify(res.data.tree, null, 4)
          });
        });
      }
    });
  }

  private _editSchemaName() {
    this.setState({
      displayDialog: true
    });
  }

  private _renderDialogFooter() {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => this.setState({ displayDialog: false })}
          className="p-mb-2"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={() => this._updateSchemaName()}
          className="p-mb-2"
        />
      </div>
    );
  }

  private _renderDeleteFooter() {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            this.props.hideSidebar();
            this.setState({ showConfirmDeleteDialog: false });
          }}
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
        />
        <Button
          label="Delete"
          icon="pi pi-check"
          onClick={() => this._deleteDecisionTree()}
          className="p-button-danger"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
        />
      </div>
    );
  }

  private _updateSchemaName() {
    if (!this.state.currentTreeName) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: "The name hasn’t been changed!",
      });
      return;
    }
    if (this.props.schema) {
      this.decisionTreeEditorService.updateSchemaName(this.props.schema, this.state.currentTreeName).then((res) => {
        if (res) {
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: 'The schema name has been updated!',
          });
          this.setState({
            displayDialog: false
          });
          this.props.refreshSchemas(this.state.currentTreeName as string, this.props.schema?.key as string);
        }
      });
    }
  }

  private _deleteDecisionTree() {
    if (this.props.schema) {
      this.decisionTreeEditorService.deleteOne(this.props.schema.key).then((res) => {
        if (res) {
          this.setState({
            showConfirmDeleteDialog: false
          });
          this.props.refreshSchemas('', '');
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: 'Schema deleted',
          });
        }
      });
    }
  }

  changeZoom(factor: number) {
    if (this.state.currentZoom + factor > 0.1 && this.state.currentZoom + factor < 5) {
      this.props.view.setZoom(this.state.currentZoom + factor, true);
      this.setState({
        currentZoom: this.state.currentZoom + factor
      });
    }
  }

  /**
   * Reset zoom to factor 1
   */
  resetZoom() {
    this.props.view.setZoom(1.5, true);
    this.setState({
      currentZoom: 1.5
    });
  }

  render() {

    this.leftContents = (
      <React.Fragment>
        <h2 className="editorTitle">Decision tree editor</h2>
        <div className="toolbarCenter">
          <div className="toolbarCenterRequirement">
            <InputText
              value={(this.props.schema) ? this.props?.schema?.name ?? null : ''}
              placeholder="Schema name"
              style={{ width: '20rem' }}
              className="requirementInput"
              disabled={!this.props.schema}
              onChange={(e) => {
                this.props.changeSchemaName(e.currentTarget.value);
              }}
            />
            <p>{this.props.schema?.key ? `<${this.props.schema.key}>` : ''}</p>
          </div>
        </div>
      </React.Fragment>
    );
    this.rightContents = (
      <div className="actionElements">
        <div className="currentTools">
          <p>{this.props.currenToolsName}</p>
        </div>
        <Button className="p-button-success"
                tooltip={this.state.lockSelect ? 'Lock diagram navigation with mouse' : 'Unlock diagram navigation with mouse'}
                disabled={!this.props.schema}
                style={{
                  backgroundColor: this.state.lockSelect ? 'rgba(0, 145, 152, 0.7)' : 'rgba(0, 145, 152)'
                  , borderColor: this.state.lockSelect ? 'rgba(0, 145, 152, 0.7)' : 'rgba(0, 145, 152)'
                }}
                icon={!this.state.lockSelect ? "pi pi-lock" : "pi pi-unlock"} onClick={() => {
          this.setState({
            lockSelect: !this.state.lockSelect
          }, () => {
            this.props.lockSelect(this.state.lockSelect);
          });
        }}
        />
        <Button className="p-button-success"
                tooltip="Zoom-in"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                disabled={!this.props.schema}
                icon="pi pi-search-plus" onClick={() => {
          this.changeZoom(-0.1);
        }}
        />
        <Button className="p-button-success"
                tooltip="Reset zoom"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                disabled={!this.props.schema}
                onClick={() => {
                  this.resetZoom();
                }}
        >100%</Button>
        <Button className="p-button-success"
                tooltip="Zoom-out"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                disabled={!this.props.schema}
                icon="pi pi-search-minus" onClick={() => {
          this.changeZoom(+0.1);
        }}
        />
        <Button className="p-button-success"
                tooltip="Undo"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                disabled={!this.props.schema}
                icon="pi pi-undo" onClick={() => {
          this.props.view.getCommandStack().undo();
        }}
        />
        <Button className="p-button-success"
                tooltip="Redo"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7', transform: "scaleX(-1)" }}
                disabled={!this.props.schema}
                icon="pi pi-undo"
                onClick={() => {
                  this.props.view.getCommandStack().redo();
                }}
        />
        <Button className="p-button-success" disabled={!this.props.schema} icon="pi pi-eye"
                tooltip="JSON result (after submission !)"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7', transform: "scaleX(-1)" }}
                onClick={() => {
                  this.getBetonJSON();
                }}
        />
        <Button className="save" icon="pi pi-save" disabled={!this.props.changes}
                style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                onClick={() => this.saveSchema()}/>
        <Button className="p-button-danger" icon="pi pi-trash" disabled={!this.props.schema} onClick={() => {
          if (this.props.schema) {
            this.props.hideSidebar();
            this.setState({
              showConfirmDeleteDialog: true
            });
          }
          else {
            this.toast?.show({
              severity: SeverityEnum.ERROR,
              detail: 'Select a schema first !',
            });

          }
        }}
                tooltip="Delete schema and decision_tree"
                tooltipOptions={{ position: 'left' }}
        />
      </div>
    );

    let schemaName;
    if (!this.props.schema) {
      schemaName = (
        <div>
          <label htmlFor="key">
            Clé
          </label>
          <Input.Text
            id="key"
            value={this.state.currentKeyName}
            onChange={(e) => {
              this.setState({
                currentKeyName: e.target.value
              });
            }}
          />
        </div>);
    }

    return (
      <div>
        <Toast ref={(el) => (this.toast = el)}/>
        <Toolbar left={this.leftContents} right={this.rightContents}/>


        <Dialog
          header={(this.props.schema) ? "Change decision tree name" : "Choose a name for the decision tree"}
          footer={this._renderDialogFooter()}
          visible={this.state.displayDialog}
          style={{ width: '500px' }}
          onHide={() => {
            this.setState({
              displayDialog: false
            }, () => {
              this.props.resetSidebar();
            });
          }}>
          <label htmlFor="name">
            Decision tree name
          </label>
          <Input.Text
            id="name"
            value={this.state.currentTreeName ?? this.props.schema?.name}
            onChange={(e) => {
              this.setState({
                currentTreeName: e.target.value
              });
            }}
          />
          {schemaName}
        </Dialog>
        <Dialog
          visible={this.state.showConfirmDeleteDialog}
          onHide={() => this.setState({ showConfirmDeleteDialog: false }, () => {
            this.props.resetSidebar();
          })}
          header="Confirmation"
          footer={this._renderDeleteFooter()}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Do you want to delete this decision tree ?</span>
          </div>
        </Dialog>
        <Dialog
          onHide={() => {
            this.setState({
              showJSONDialog: false
            }, () => {
              this.props.resetSidebar();
            });
          }}
          header={this.props.schema?.key ?? ''}
          footer={
            <div>
              <Button
                label="Copy"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                onClick={async () => {
                  prompt("Copy to clipboard: Ctrl+C, Enter", this.state.currentBetonJSON ?? '');
                }}
                className="p-button-danger"
              />
            </div>
          }
          visible={this.state.showJSONDialog}
          style={{ width: '1000px', height: '500px' }}
        >
          <pre id="json">{this.state.currentBetonJSON}</pre>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(Topbar);
