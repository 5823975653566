import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FormService } from '../../form/services/FormService';
import { AdminDiagramService } from "../services/AdminDiagramService";

interface Props extends WithTranslation {
}

interface States {
}

class AdminDiagram extends React.Component<Props, States> {
  private readonly fService: FormService;
  private readonly diagramService: AdminDiagramService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.fService = new FormService();
    this.diagramService = new AdminDiagramService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }
    this.diagramService
      .uploadManyDiagram(e.files[0])
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.fService.t(this.t, "ui_admin_diagram_toast_success", false),
        });
        this.fileUpload?.clear();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `${this.fService.t(this.t, "ui_admin_diagram_toast_error", false)} : ${message}`,
        });
      });
  }


  render() {
    return (
      <Card title={this.fService.t(this.t, "ui_admin_diagram_title", false)}>
        <Toast ref={(el) => (this.toast = el)}/>
        <Button
          label={this.fService.t(this.t, "ui_admin_diagram_download_button", false)}
          className="p-mb-2"
          // onClick={() => this.handleDownload()}
        />
        <FileUpload
          ref={(el) => (this.fileUpload = el)}
          accept="application/json"
          customUpload={true}
          uploadHandler={(e: { files: File[] }) => this.handleUpload(e)}
        ></FileUpload>
      </Card>
    )
  }
}

export default withTranslation()(AdminDiagram);
