import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FormService } from "../../form/services/FormService";

import Editor from "@monaco-editor/react";
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

import { AdminHtmlsService } from "../services/AdminHTMLService";
import { HtmlDTO } from "../dto/HtmlDTO";

import CodeSidebarHTML from "./components/HTML/CodeSidebarHTML";
import QuestionSelectorHTML from "./components/HTML/QuestionSelectorHTML";

import "./CodeEditor.scss";
import { Checkbox } from "primereact/checkbox";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";

interface Props extends WithTranslation {
}

interface States {
  displayConfirmation: boolean;
  displayNewScriptConfirmation: boolean;
  editorText: string;
  editorScriptName: string;
  refreshScriptsState: boolean;
  editorRef: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | null>;
  isActive: boolean;
  deleteHtml: boolean;
  downloadHtml: boolean;
}


class CodeEditorHTML extends React.Component<Props, States> {


  private toast: Toast | null;
  private t: TFunction;
  private formService: FormService;

  private readonly htmlService: AdminHtmlsService;
  private readonly htmlDefaultScript: string;

  private leftContents: any;
  private rightContents: any;


  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.toast = null;
    this.formService = new FormService();

    // Languages
    this.htmlService = new AdminHtmlsService();
    this.htmlDefaultScript =
      "<!--Variables to use :-->\n\n<!--\n"
      + "{{question.QUESTION_NAME}} // Question's title\n\n"
      + "{{value.QUESTION_NAME}} // Answer's question\n\n"
      + "{{requirement.title.REQUIREMENT_VALUE}} // Requirement's title\n\n"
      + "{{requirement.answer.REQUIREMENT_VALUE}} // Requirement's value\n\n"
      + "{{translation.PRISMIC_KEY}} // Prismic's key \n-->\n\n"
    /* +"<!--\nStructure :\n\n<style>\n\n</style>"
    +"\n<picture>\n\n</picture>"
    +"\n<h1>\n\n</h1>"
    +"\n<div class=''>\n\n</div> \n-->" */
    ;

    this.state = {
      displayConfirmation: false,
      editorText: "",
      editorScriptName: "",
      displayNewScriptConfirmation: false,
      refreshScriptsState: false,
      editorRef: React.createRef<monaco.editor.IStandaloneCodeEditor | null>(),
      isActive: false,
      deleteHtml: false,
      downloadHtml: false,
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.resetEditorText = this.resetEditorText.bind(this);
    this.handleOneUpload = this.handleOneUpload.bind(this);
    this.handleEditorDidMount = this.handleEditorDidMount.bind(this);

  }

  public componentDidMount(): void {
    /* this.editorRef.current?.editor?.layout();
    if (autofocus) {
        this.editorRef.current?.editor?.focus();
    } */
  }


  refreshScript = (scriptToView) => {

    this.setState(
      {
        editorScriptName: scriptToView
      }
    );

    this.getHtmlToViewText(scriptToView);

  };

  getHtmlToViewText(str: string) {
    if (!str || !str.includes(".html")) {
      return;
    }

    this.htmlService
      .getOne(str)
      .then((response: AxiosResponse<string>) => {
        this.setState({ editorText: response.data, editorScriptName: str });

      });

    this.htmlService.getOneIsActive(str).then((res: any) => {
      if (res) {
        this.setState({
          isActive: res.data
        });
      }
    });
  }

  /**
   * On upload for one script
   */
  handleOneUpload() {

    let fileName = this.state.editorScriptName;

    if (!fileName) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `Please enter a script name !`,
      });
      return;
    }

    let extensionPart = fileName.substring(fileName.lastIndexOf('.'));

    const allowedTypes = ['.html'];

    // = Validate the types
    if (!allowedTypes.includes(extensionPart)) {
      fileName += ".html";
    }

    const dto = new HtmlDTO(fileName, this.state.editorText);

    this.htmlService
      .uploadOne(dto)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.formService.t(this.t, "ui_admin_html_upload_one_toast_success", false),
        });
        //this.resetEditorText();
        this.setState({ refreshScriptsState: true });

      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `${this.formService.t(this.t, "ui_admin_html_upload_one_toast_error", false)} : ${message}`,
        });
      });
  }


  resetEditorText() {
    this.setState({
      editorText: this.htmlDefaultScript,
      displayNewScriptConfirmation: false,
      editorScriptName: ""
    });
  }


  /* 
    Actions handeling
   */
  handleEditorChange(value, event) {

    this.setState({ editorText: value });

  }

  /* 
    Validate methods
  */
  validationWindowForNewScript() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayNewScriptConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.resetEditorText()}
          autoFocus
        />
      </div>
    );
  }


  handleDeletedScript = () => {
    this.resetEditorText();
  };


  handleEditorDidMount(editor, monaco) {

    this.state.editorRef.current = editor;

  }

  selectQuestion = (questionSelected) => {
    this.state.editorRef.current?.trigger('keyboard', 'type'
      , {
        text:
          '{{question.' + questionSelected.name + '}}\n\n'
      });
  };

  selectQuestionValue = (questionValueSelected) => {
    this.state.editorRef.current?.trigger('keyboard', 'type'
      , {
        text:
          '{{value.' + questionValueSelected.name + '}}\n\n'
      });
  };


  selectRequirement = (requirementSelected) => {
    this.state.editorRef.current?.trigger('keyboard', 'type'
      , {
        text:
          '{{requirement.title.' + requirementSelected.requirement + '}}\n\n'
      });
  };

  selectRequirementAnswer = (requirementAnswerSelected) => {
    this.state.editorRef.current?.trigger('keyboard', 'type'
      , {
        text:
          '{{requirement.answer.' + requirementAnswerSelected.requirement + '}}\n\n'
      });
  };

  selectPrismicKey = (prismicKeySelected) => {
    this.state.editorRef.current?.trigger('keyboard', 'type'
      , {
        text:
          '{{translation.' + prismicKeySelected + '}}\n'
      });
  };

  selectImagePrismicKey = (prismicKeySelected) => {
    this.state.editorRef.current?.trigger('keyboard', 'type'
      , {
        text:
          '<img width="200" src="data:image/jpeg;base64,{{image.' + prismicKeySelected + '}}" />'
      });
  };

  render() {

    this.leftContents = (
      <React.Fragment>
        <h2 className="editorTitle">HTML template editor</h2>
        <div className="toolbarCenterRequirement">
          <div className="">
            <InputText
              value={this.state.editorScriptName}
              onChange={(e) => this.setState({ editorScriptName: e.currentTarget.value })}

              placeholder="Template name"
              style={{ width: '15rem' }}
            />
          </div>
          <div id="isActiveCheckbox">
            <div>
              <label>Actif: </label>
              <Checkbox checked={this.state.isActive}
                        onChange={e => {
                          if (!this.state.editorScriptName) {
                            this.toast?.show({
                              severity: SeverityEnum.ERROR,
                              detail: `Please select one template !`,
                            });
                            return;
                          }
                          this.htmlService.activeOne(this.state.editorScriptName, e.checked).then(res => {
                            if (res.status === 201) {
                              this.toast?.show({
                                severity: SeverityEnum.SUCCESS,
                                detail: `Le changement a bien été effectué !`,
                              });
                              this.setState({ isActive: e.checked, refreshScriptsState: true });
                            }
                          });
                        }}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    this.rightContents = (
      <div className="action">
        <Button
          type="button"
          tooltip="Download HTML template"
          icon="pi pi-download"
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
          tooltipOptions={{ position: 'left' }}
          onClick={() => this.setState({
            downloadHtml: true,
          }, () => {
            this.setState({
              downloadHtml: false
            });
          })}
        />
        <Button
          tooltip="Save HTML template"
          icon="pi pi-save"
          className="p-button-danger"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          tooltipOptions={{ position: 'left' }}
          onClick={this.handleOneUpload}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => this.setState({
            deleteHtml: true,
          }, () => {
            this.setState({
              deleteHtml: false
            });
          })}
        />
      </div>
    );

    return (
      <div className="editor-script">
        <div className="sideBarHTML">
          <CodeSidebarHTML
            scriptToViewCallback={this.refreshScript}
            reloadScripts={this.state.refreshScriptsState}
            resetRefreshScriptsState={() => this.setState({ refreshScriptsState: false })}
            deletedScript={this.handleDeletedScript}
            actionDeleteHtml={this.state.deleteHtml}
            actionDownloadHtml={this.state.downloadHtml}
          />
        </div>
        <Toolbar left={this.leftContents} right={this.rightContents}/>
        <div className="workPlace">
          <Card /*title={"Code editor HTML" this.formService.t(this.t, "ui_admin_editor_js_title", false) }*/>
            <Toast ref={(el) => (this.toast = el)}/>

            <div className="p-grid" id="scriptEditorHTML">
              <div className="p-col-12-jc-center" id="editorAreaHTML">
                <Editor
                  height="100%"
                  defaultLanguage="html"
                  defaultValue={this.htmlDefaultScript}
                  value={this.state.editorText}
                  onChange={this.handleEditorChange}
                  onMount={this.handleEditorDidMount}
                />
              </div>

              <div className="p-col-12 p-d-flex p-jc-center" id="questionSelectorHTML">
                <QuestionSelectorHTML
                  questionSelected={this.selectQuestion}
                  questionValueSelected={this.selectQuestionValue}
                  requirementSelected={this.selectRequirement}
                  requirementAnswerSelected={this.selectRequirementAnswer}
                  prismicKeySelected={this.selectPrismicKey}
                  imagePrismicKeySelected={this.selectImagePrismicKey}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

}

export default withTranslation()(CodeEditorHTML);
