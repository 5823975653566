import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { Requirements } from '../models/Requirements';
export class RequirementsService extends APIService {


  /**
   * Get many requirements
   * 
   * @role Admin
   */
  getMany(): Promise<AxiosResponse<Requirements[]>> {
    return this.http.get<Requirements[]>('/forms/default/requirements');
  }

  /**
   * Upload and replace the requirements
   *
   * @role Admin
   */
  uploadRequirements(file: File) {
    const formData = new FormData();
    formData.append('requirements', file);
    return this.http.put<void>('/forms/default/requirements/upload', formData);
  }

  /**
   * Download the current requirements
   *
   * @role Admin
   */
  downloadRequirements() {
    return this.http.get<Blob>('/forms/default/requirements/download', { responseType: 'blob' });
  }

  activeOne(editorRequirementName: string, isActive: boolean) {
    return this.http.post<void>(`/forms/default/requirements/${editorRequirementName}/activeOne/${isActive}`);
  }
}
