import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { ScriptDTO } from '../dto/ScriptDTO';
import {Script} from "../models/Script";

export class AdminScriptsService extends APIService {

  /**
   * Get many scripts
   */
  getMany(): Promise<AxiosResponse<Script[]>> {
    return this.http.get<Script[]>('/scripts');
  }

  /**
   * Get the text of a script by name
   */
   getOne(name: string): Promise<AxiosResponse<string>>{
    return this.http.get<string>(`/scripts/${name}/text`);
  }
  
  /**
   * Download a script by name
   */
  downloadOne(name: string) {
    return this.http.get<Blob>(`/scripts/${name}/download`, { responseType: 'blob' });
  }


  /**
   * Upload many scripts
   */
  uploadMany(files: File[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    return this.http.post<void>(`/scripts/upload`, formData);
  }

  /**
   * Upload one script
   */
  uploadOne(dto: ScriptDTO) {
    return this.http.post<void>(`/scripts/uploadone`, dto);
  }

  /**
   * Delete one script
   */
  deleteOne(name: string) {
    return this.http.delete<void>(`/scripts/${name}`);
  }
}
