import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {Dropdown} from "primereact/dropdown";

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormService } from "../../../../form/services/FormService";

import "./QuestionSelectorJS.scss"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";


interface Props extends WithTranslation {
    questionSelected: any;
}

interface States {
    questionListAnswerList: { name: string, label: string, values: string[] }[];
    selection: any;
    changes: boolean;

}


class QuestionSelectorJS extends React.Component<Props, States> {


    private toast: Toast | null;
    private t: TFunction;
    private formService: FormService;
    

    constructor(props: Props) {
        super(props);
        this.t = this.props.t;
        this.toast = null;
        this.formService = new FormService();        
        this.state = {
            selection: null,
            questionListAnswerList: [],
            changes: false,
        }
    }

    componentDidMount() {
        this._getDatas();        
    }

    private _setSelectionUserDataValue(value: string) {
        this.setState({
            selection: value,
            changes: true
        }, ()=>{
            this.props.questionSelected(this.state.selection.name);
        })
    }

    private _getDatas() {
        this.formService.getQuestionsByType([]).then((res) => {
            if (res.data) {
                // @ts-ignore
                this.setState({
                    // @ts-ignore
                    questionListAnswerList: res.data
                });
            }
        });
    }


    render() {
        return (
        <div className="question-selector">

            <DataTable      
                value={this.state.questionListAnswerList}
                selection={this.state.selection}            
                tableClassName="table-custom"
                scrollable 
                scrollHeight="100%"
                onSelectionChange={(e) => {
                    this._setSelectionUserDataValue(e.value);
                }}
                dataKey="name"
                stateStorage="local"
                selectionMode="single"
            >
                <Column
                    field="name"
                    sortable
                    filter
                    header={"Available questions"}
                    filterMatchMode="contains"
                />
            </DataTable>                
        </div>
        );
    }

}
export default withTranslation()(QuestionSelectorJS);
