
import { AxiosResponse } from "axios";
import { WithTranslation, withTranslation, } from "react-i18next";
import { TFunction } from "i18next";
import { Toast } from "primereact/toast";
import {Dropdown} from "primereact/dropdown";
import React from "react";
import { FormService } from "../../../../form/services/FormService";

import "./QuestionSelectorHTML.scss"
import {TranslationService} from '../../../../form/services/TranslationService'
import {RequirementsService} from '../../../services/RequirementsService'
import { Requirements } from "../../../models/Requirements";



interface Props extends WithTranslation {
    questionSelected: any;
    questionValueSelected: any;
    requirementSelected: any;
    requirementAnswerSelected: any;
    prismicKeySelected: any;
    imagePrismicKeySelected: any;
}

interface States {
    questionListAnswerList: { name: string, label: string, values: string[] }[];
    selectionQuestion: any;
    selectionQuestionValue: any;
    changes: boolean;
    prismicKeys: { name: string}[];
    selectionPrismic: any;
    requirements: Requirements[];
    selectionRequirement: any;
    selectionRequirementAnswer: any;

}


class QuestionSelectorHTML extends React.Component<Props, States> {


    private toast: Toast | null;
    private t: TFunction;
    private formService: FormService;
    private translationService: TranslationService;
    private requirementService: RequirementsService;


    constructor(props: Props) {
        super(props);
        this.t = this.props.t;
        this.toast = null;
        this.formService = new FormService();
        this.translationService = new TranslationService();
        this.requirementService = new RequirementsService();

        this.state = {
            selectionQuestion: null,
            selectionQuestionValue: null,
            questionListAnswerList: [],
            changes: false,
            prismicKeys: [],
            selectionPrismic: null,
            requirements: [],
            selectionRequirement: null,
            selectionRequirementAnswer: null,
        }

    
    }


     componentDidMount() {

        this._getQuestions();
        this.getRequirements();
        this.getPrismicKeys();
    }
    

    private _getConfigurationContent() {
        if (this.state.questionListAnswerList) {
            return this._getNodeAnswerListContent();
        }
    }
    private _setSelectionUserDataValue(selector: number, value: string) {
        
        if(selector===1){

            this.setState({
            selectionQuestion: value,
            changes: true
            }, ()=>{
                this.props.questionSelected(this.state.selectionQuestion);
                /* this.props.questionSelected(this.state.selectionQuestion.name); */
            });
        }
        else if(selector===2){
            this.setState({
                selectionQuestionValue: value,
                changes: true
            }, ()=>{
                this.props.questionValueSelected(this.state.selectionQuestionValue);
            });
        }
        else if(selector==3){
            this.setState({
                selectionRequirement: value,
                changes: true
            }, ()=>{
                this.props.requirementSelected(this.state.selectionRequirement);
            });
        }
        else if(selector===4){
            this.setState({
                selectionRequirementAnswer: value,
                changes: true
            }, ()=>{
                this.props.requirementAnswerSelected(this.state.selectionRequirementAnswer);
            });
        }
        else if(selector==5){
            this.setState({
                selectionPrismic: value,
                changes: true
            }, ()=>{
                this.props.prismicKeySelected(this.state.selectionPrismic.name);
            });
        }

        else if(selector==6){
            this.setState({
                selectionPrismic: value,
                changes: true
            }, ()=>{
                this.props.imagePrismicKeySelected(this.state.selectionPrismic.name);
            });
        }

    }

    private _getNodeAnswerListContent() {
        let content: any[] = [];

        content.push(<h2>Available tags</h2>)

        content.push(
            <React.Fragment key={"htmlHelper"}>
                <h3 className="selector-help">{"Questions"}</h3>
                <div className="section-selectors">
                    <span className="sub-title">{"Question"}</span>
                    <Dropdown 
                    value={this.state.selectionQuestion} 
                    options={ this.state.questionListAnswerList}
                    onChange={(e) => {
                        this._setSelectionUserDataValue(1, e.value);
                    }}
                    optionLabel="name" 
                    filter 
                    filterBy="name" 
                    placeholder="Select a question"
                    />
                    <span className="sub-title">{"Answer"}</span>
                    <Dropdown 
                    value={this.state.selectionQuestionValue} 
                    options={ this.state.questionListAnswerList}
                    onChange={(e) => {
                        this._setSelectionUserDataValue(2, e.value);
                    }} 
                    optionLabel="name" 
                    filter 
                    filterBy="name" 
                    placeholder="Select a question"
                    />
                </div>


                <h3 className="selector-help">{"Requirements"}</h3>
                <div className="section-selectors">
                    <span className="sub-title">{"Title"}</span>
                    <Dropdown
                    value={this.state.selectionRequirement}
                    options={ this.state.requirements[0]?.requirements}
                    onChange={(e) => {
                        this._setSelectionUserDataValue(3, e.value);
                    }}
                    optionLabel="requirement"
                    filter 
                    filterBy="requirement"
                    placeholder={"Select a requirement"}
                    />
                    <span className="sub-title">{"Answer"}</span>
                    <Dropdown
                    value={this.state.selectionRequirementAnswer}
                    options={ this.state.requirements[0]?.requirements}
                    onChange={(e) => {
                        this._setSelectionUserDataValue(4, e.value);
                    }}
                    optionLabel="requirement"
                    filter 
                    filterBy="requirement"
                    placeholder={"Select a requirement"}
                    />
                </div>

                <h3 className="selector-help">{"Prismic keys"}</h3>
                <div className="section-selectors">
                    <span className="sub-title">{"Key"}</span>
                    <Dropdown
                    value={this.state.selectionPrismic}
                    options={ this.state.prismicKeys}
                    onChange={(e) => {
                        this._setSelectionUserDataValue(5, e.value);
                    }}
                    optionLabel="name"
                    filter 
                    filterBy="name"
                    placeholder={"Select a prismic key"}
                    />   
                </div>

                <h3 className="selector-help">{"Images (Prismic keys)"}</h3>
                <div className="section-selectors">
                    <span className="sub-title">{"Key"}</span>
                    <Dropdown
                        value={this.state.selectionPrismic}
                        options={ this.state.prismicKeys}
                        onChange={(e) => {
                            this._setSelectionUserDataValue(6, e.value);
                        }}
                        optionLabel="name"
                        filter
                        filterBy="name"
                        placeholder={"Select a prismic key"}
                    />
                </div>

            </React.Fragment>
        )


        return content;
    }


    private _getQuestions() {
        this.formService.getQuestionsByType([]).then((res) => {
            if (res.data) {
                // @ts-ignore
                this.setState({
                    // @ts-ignore
                    questionListAnswerList: res.data
                }/* ,()=>{
                    console.log(this.state.questionListAnswerList);
                } */);
            }
        });
    }

    /**
    * Load requirements
    */
    private getRequirements() {
        this.requirementService
        .getMany()
        .then((response: AxiosResponse<Requirements[]>) => {
            this.setState({ requirements: response.data }/* ,()=>{
                console.log(this.state.requirements[0].requirements);
            } */);
        });
    }    

    /**
    * Load prismic keys
    */
    private async getPrismicKeys() {
        await this.translationService.get('fr')
        .then((response) => { return response; })
        .then((data) => {
                        
            let transformObj = [{name : ''}];
    
            for (let i=0; i<Object.keys(data.data).length; i++){
                transformObj[i] = {name : Object.keys(data.data)[i]} ;
            }
            this.setState({prismicKeys : transformObj}/* , ()=>{
                console.log(this.state.prismicKeys);
            } */);
            
        });
    }  



    render() {
        return (
        <div className="question-selector">
                {this._getConfigurationContent()}
        </div>
        );
    }

}
export default withTranslation()(QuestionSelectorHTML);
