import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthService } from '../services/AuthService';
import { RoleEnum } from '../../shared/enum/RoleEnum';

interface Props {
  path: string;
}

export default class AdminRoute extends React.Component<Props> {

  render() {
    const isAuth = AuthService.isAuth;
    let role: RoleEnum | undefined;

    if (isAuth) {
      role = AuthService.user?.role;
    }

    return (
      <Route path={this.props.path} render={({ location }) => isAuth && role === RoleEnum.ADMIN
        ? this.props.children
        : <Redirect to={{ pathname: '/', state: { from: location } }}/>}/>
    );
  }
}