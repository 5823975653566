import { Modal } from "@bbri/ui";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ImageWrapper.scss";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from "primereact/button";

interface Props extends WithTranslation, RouteComponentProps {
  src: string;
  alt: string;
}

interface States {
  isOpen: boolean;
}

class ImageWrapper extends Component<Props, States> {

  private historyListener: null | (() => void);

  isOnMobile: boolean = false;

  changedWidth: boolean = false;

  constructor(props: Props) {
    super(props);

    this.historyListener = null;

    this.state = {
      isOpen: false,
    };
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isOnMobile = true;
    }
  }

  handleOpenModal() {
    this.historyListener = this.props.history.listen((location, action) => {
      if (action === "POP") {
        this.handleCloseModal();
      }
    });

    this.setState({ isOpen: true });
  }

  handleCloseModal() {

    if (this.historyListener) {
      this.historyListener();
    }

    this.setState({ isOpen: false });
  }

  increaseImage() {
    let size = document.getElementsByClassName('image-wrapper-zoom')[0]['width'];
    document.getElementsByClassName('image-wrapper-zoom')[0]['style'].maxWidth = "1000%";
    document.getElementsByClassName('image-wrapper-zoom')[0]['width'] = size + ((size * 50) / 100);

  }

  decreaseImage() {
    let size = document.getElementsByClassName('image-wrapper-zoom')[0]['width'];
    document.getElementsByClassName('image-wrapper-zoom')[0]['width'] = size - ((size * 50) / 100);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="image-wrapper"
          onClick={() => this.handleOpenModal()}
        >
          <img src={this.props.src} alt={this.props.alt}/>
          <i className="pi pi-search-plus zoom"/>
        </div>
        {this.state.isOpen && (
          <Modal onHide={() => this.handleCloseModal()}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.alt}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ 'overflow-x': 'scroll !important' }}>

              <img
                className="image-wrapper-zoom"
                src={this.props.src}
                alt={this.props.alt}
              />
              {this.isOnMobile ?
                <div className="center">
                  <Button
                    icon="pi pi-plus"
                    onClick={() => this.increaseImage()}
                    className="button"
                    autoFocus
                  />
                  <Button
                    icon="pi pi-minus"
                    onClick={() => this.decreaseImage()}
                    className="button"
                    autoFocus
                  />
                </div>
                : null}
            </Modal.Body>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(ImageWrapper));
