import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { TFunction } from "i18next";
import { FormService } from "../../../form/services/FormService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/components/button/Button";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import "./FormEditorSidebar.scss";
import { Form } from "../../../form/models/Form";
import { FormEditorService } from "../services/FormEditorService";
import { SeverityEnum } from "../../../shared/enum/SeverityEnum";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { QuestionType } from "../../../form/enum/QuestionType";
import { InputText } from "primereact/inputtext";
import { Chips } from 'primereact/chips';
import { Toolbar } from "primereact/toolbar";
import { FormType } from "../../../form/enum/FormType";
import { RadioButton } from "primereact/radiobutton";
import { DecisionTreeEditorService } from "../../services/DecisionTreeEditorService";
import { DecisionTree } from "../../../admin/models/DecisionTree";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";


interface States {
  questions: any[];
  currentQuestion: any;
  displayAddQuestionDialog: boolean;
  displayDeleteAllQuestionDialog: boolean;
  displayConfigFormDialog: boolean;
  currentNewQuestion: any;
  currentNewQuestionType: any;
  formConfig: {
      googleTag: string,
      prismic: string[],
      type: FormType,
      pdfWidth: number,
      docxGeneration: boolean,
      pdfFromDocx: boolean,
      mailTo: string,
      graphDecisionTree: string | null,
      diagramDecisionTree: string | null };
  form: Form | null;
}

interface Props extends WithTranslation {
  questions: any[];
  form: Form | null;
  onQuestionSelection: (question) => void;
  refreshQuestionList: () => void;
  prismicKeys: string[];
  addQuestion: (question) => void;
  deleteAllQuestion: () => void;
  saveFormConfig: (formConfig) => void;
  currentQuestion: any;
  decisionTrees: DecisionTree[]
}

class FormEditorSidebar extends React.Component<Props, States> {

  private t: TFunction;
  private formService: FormService;
  private formEditorService: FormEditorService;
  private toast: Toast | null;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.formEditorService = new FormEditorService();
    this.t = this.props.t;
    this.toast = null;

    this.state = {
      questions: [],
      currentQuestion: null,
      displayAddQuestionDialog: false,
      displayDeleteAllQuestionDialog: false,
      displayConfigFormDialog: false,
      currentNewQuestionType: null,
      currentNewQuestion: null,
      formConfig: {
          googleTag: '',
          prismic: [],
          type: FormType.REQUIREMENT,
          pdfWidth: 1200,
          docxGeneration: false,
          pdfFromDocx: false,
          mailTo: "",
          graphDecisionTree: null,
          diagramDecisionTree: null },
      form: null
    };
  }

  private _onQuestionOrder(e: any) {
    this.formEditorService.updateQuestions(e).then(() => {
      this.props.refreshQuestionList();
      this.toast?.show({
        severity: SeverityEnum.SUCCESS,
        detail: 'Question déplacée!',
      });
    });
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    this.setState({
      questions: nextProps.questions
    });
    if (nextProps.form) {
      const formConfig = {
        googleTag: nextProps.form.googleTag ?? '',
        prismic: nextProps.form.prismic ?? [],
        type: nextProps.form.type ?? FormType.REQUIREMENT,
        pdfWidth: nextProps.form.pdfWidth ?? 1200,
        docxGeneration: nextProps.form.docxGeneration ?? false,
        pdfFromDocx: nextProps.form.pdfFromDocx ?? false,
        mailTo: nextProps.form.mailTo ?? "",
        graphDecisionTree: nextProps.form.graphDecisionTree ?? null,
        diagramDecisionTree: nextProps.form.diagramDecisionTree ?? null
      };
      this.setState({
        formConfig
      });
    }
    if (nextProps.currentQuestion) {
      this.setState({
        currentQuestion: nextProps.currentQuestion
      });
    }
  }

  private _renderAddQuestionDialogFooter() {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          onClick={() => this.setState({
            displayAddQuestionDialog: false,
            currentNewQuestion: null,
            currentNewQuestionType: null
          })}
          className="p-mb-2"
        />
        <Button
          label="Sauver"
          icon="pi pi-check"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          onClick={() => {
            let question: any = {
              values: [],
              question: this.state.currentNewQuestion,
              type: this.state.currentNewQuestionType
            };
            switch (question.type) {
              case QuestionType.NUMBER:
                question.validation = {
                  min: 0,
                  step: 1
                };
            }
            this.props.addQuestion(question);
            this.setState({
              displayAddQuestionDialog: false,
            });
          }}
          disabled={(!this.state.currentNewQuestionType || !this.state.currentNewQuestion)}

          className="p-mb-2"
        />
      </div>
    );
  }

  private _addNewEmptyQuestion() {
    let question: any = {
      values: [],
      question: `New_question_${this.state.questions.length + 1}`,
      type: null
    };
    for (const q of this.props.questions) {
      //check if question already exist
      if (q.question === question.question) {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `Question with the name ${question.question} already exists !`,
        });
        return;
      }
    }
    this.props.addQuestion(question);
    this.setState({ currentQuestion: question });
    this.props.onQuestionSelection(question);
    this.setState({
      displayAddQuestionDialog: false,
    });
  }

  render() {

    let questionTypes: any[] = [];
    for (let i in QuestionType) {
      questionTypes.push({ name: QuestionType[i], label: QuestionType[i] });
    }

    let formConfigPrismic: any[] = [];
    let commonPrismic: any[] = [];
    for (let prismic of this.state.formConfig.prismic) {
      if (!prismic.includes('common')) {
        formConfigPrismic.push(prismic);
      }
      else {
        commonPrismic.push(prismic);
      }
    }

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <div>
          <DataTable className="questionList" value={this.state.questions}
            id="p-datatable-scrollable-body"
            reorderableColumns
            onRowReorder={(e: any) => {
              this._onQuestionOrder(e.value);
            }}
            selection={this.state.currentQuestion}
            onSelectionChange={e => {
              this.setState({ currentQuestion: e.value });
              this.props.onQuestionSelection(e.value);
            }}
            selectionMode="single"
            dataKey="question"
            scrollHeight="70vh"
            scrollable
          >
            <Column key="question" columnKey="question" field="question" style={{ width: '50px' }} header="List of questions" />
            <Column rowReorder style={{ width: '15px' }} rowReorderIcon="pi pi-ellipsis-v" />
          </DataTable>
          <Toolbar
            left={() => (
              <div>
                <Button
                  type="button"
                  icon="pi pi-cog"
                  tooltip="Config form"
                  onClick={() => this.setState({ displayConfigFormDialog: true })}
                  style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                  className="p-button-secondary" />
                <Button
                  icon="pi pi-plus"
                  tooltip="Add question"
                  style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                  onClick={() => this._addNewEmptyQuestion()}
                  className="p-button-success" />
              </div>
            )}
          />
        </div>
        <Dialog
          id="addQuestionDialog"
          header={"Add question"}
          footer={this._renderAddQuestionDialogFooter()}
          visible={this.state.displayAddQuestionDialog}
          style={{ width: '600px' }}
          onHide={() => {
            this.setState({
              displayAddQuestionDialog: false,
              currentNewQuestion: null,
              currentNewQuestionType: null
            });
          }}>
          <div>
            <div>
              <label>Question: </label>
              <Dropdown
                value={this.state.currentNewQuestion}
                options={this.props.prismicKeys}
                onChange={(e) => {
                  this.setState({ currentNewQuestion: e.value.name ?? e.value });
                }}
                filter
                editable
                appendTo={document.body}
                placeholder="Select a question" />
            </div>
            <div>
              <label>Type: </label>
              <Dropdown
                value={this.state.currentNewQuestionType}
                options={questionTypes}
                onChange={(e) => {
                  this.setState({ currentNewQuestionType: e.value.name ?? e.value });
                }}
                filter
                editable
                appendTo={document.body}
                placeholder="Select a question" />
            </div>
          </div>
        </Dialog>
        <Dialog header="Confirmation"
          visible={this.state.displayDeleteAllQuestionDialog}
          modal style={{ width: '450px' }}
          footer={(
            <div>
              <Button label="No" icon="pi pi-times"
                onClick={() => this.setState({ displayDeleteAllQuestionDialog: false })}
                className="p-button-text" />
              <Button label="Yes" icon="pi pi-check" onClick={() => {
                this.props.deleteAllQuestion();
                this.setState({
                  displayDeleteAllQuestionDialog: false
                });
              }}
              />
            </div>
          )}
          onHide={() => this.setState({ displayDeleteAllQuestionDialog: false })}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            <span>Voulez-vous supprimer toutes les questions ?</span>
          </div>
        </Dialog>
        <Dialog header="Form configuration"
          visible={this.state.displayConfigFormDialog}
          modal style={{ width: '850px' }}
          footer={(
            <div>
              <Button label="Cancel" icon="pi pi-times"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                className="p-button-danger"
                onClick={() => {
                  this.setState({
                    displayConfigFormDialog: false,
                  }, () => {
                    const formConfig = this.state.formConfig as any;
                    formConfig.googleTag = this.props.form?.googleTag;
                    formConfig.prismic = this.props.form?.prismic;
                    formConfig.type = this.props.form?.type
                    formConfig.pdfWidth = this.props.form?.pdfWidth;
                    formConfig.docxGeneration = this.props.form?.docxGeneration;
                    formConfig.pdfFromDocx = this.props.form?.pdfFromDocx;
                    formConfig.mailTo = this.props.form?.mailTo;
                    formConfig.graphDecisionTree = this.props.form?.graphDecisionTree
                    formConfig.diagramDecisionTree = this.props.form?.diagramDecisionTree
                    this.setState({
                      displayConfigFormDialog: false
                    });
                  });
                }} />
              <Button label="Save"
                style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                className="p-button-danger"
                icon="pi pi-check"
                onClick={() => {
                  this.props.saveFormConfig(this.state.formConfig);
                  this.setState({ displayConfigFormDialog: false });
                }}
              />
            </div>
          )}
          onHide={() => this.setState({ displayConfigFormDialog: false })}>
          <div className="formConfigModal">

            <div>
              <label>GoogleTag: </label>
              <InputText className="p-inputtext-sm block mb-2"
                placeholder="Google tag"
                value={this.state.formConfig.googleTag ?? ''}
                onChange={(e: any) => {
                  const formConfig = this.state.formConfig;
                  formConfig.googleTag = e.target.value;
                  this.setState({ formConfig });
                }}
              />
            </div>
            <div>
              <label>Prismic<span style={{ color: '#e98b3a' }}>*</span> : </label>
              <Chips value={formConfigPrismic}
                onChange={(e) => {
                  const formConfig = this.state.formConfig;
                  formConfig.prismic = e.value;
                  formConfig.prismic = formConfig.prismic.concat(commonPrismic);
                  this.setState({ formConfig });
                }}
              />
            </div>
              <div>
                  <label>Mail to : </label>
                  <InputText className="p-inputtext-sm block mb-2"
                             placeholder="Mail to"
                             value={this.state.formConfig.mailTo ?? ''}
                             onChange={(e: any) => {
                                 const formConfig = this.state.formConfig;
                                 formConfig.mailTo = e.target.value;
                                 this.setState({ formConfig });
                             }}/>
              </div>
              <div>
                  <label>PDF width: </label>
                  <InputNumber className="p-inputtext-sm block mb-2"
                             placeholder="PDF width"
                             value={this.state.formConfig.pdfWidth ?? ''}
                             onChange={(e: any) => {
                                 const formConfig = this.state.formConfig;
                                 formConfig.pdfWidth = e.value;
                                 this.setState({ formConfig });
                             }}
                  />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>Document : </label>
                      <Checkbox
                           style={{ marginRight: 10 }}
                           checked={this.state.formConfig.docxGeneration ?? false}
                           onChange={(e: any) => {
                               const formConfig = this.state.formConfig;
                               formConfig.docxGeneration = e.checked;
                               this.setState({ formConfig });
                           }}
                      />
                      <label style={{ whiteSpace: "nowrap" }}>Docx generation</label>
                      <Checkbox
                          style={{ marginLeft: 50, marginRight: 10 }}
                          checked={this.state.formConfig.pdfFromDocx ?? false}
                          onChange={(e: any) => {
                              const formConfig = this.state.formConfig;
                              formConfig.pdfFromDocx = e.checked;
                              this.setState({ formConfig });
                          }}
                      />
                      <label style={{ whiteSpace: "nowrap" }}>PDF from docx</label>
              </div>
            <div>
              <small><span style={{ color: '#e98b3a' }}>*</span>Write the key and press "enter" to add the key in the Prismic list.</small>
            </div>
            <div>
              <div>
                <label >Type : </label>
                <RadioButton style={{marginRight: 5}} name="form-type" value="graph"
                  checked={this.state.formConfig.type === FormType.GRAPH}
                  onChange={() => {
                    const formConfig = this.state.formConfig
                    formConfig.type = FormType.GRAPH
                    this.setState({
                      formConfig
                    })
                  }}></RadioButton>
                <label htmlFor="graph">Graph</label>
              </div>
              <div>
                <RadioButton style={{marginRight: 5}} name="form-type" value="requirement"
                  checked={this.state.formConfig.type === FormType.REQUIREMENT}
                  onChange={() => {
                    const formConfig = this.state.formConfig
                    formConfig.type = FormType.REQUIREMENT
                    this.setState({
                      formConfig
                    })
                  }}
                ></RadioButton>
                <label htmlFor="requirement">Requirement</label>
              </div>
            </div>
            {this.state.formConfig.type === FormType.GRAPH && (
              <>
                <div>
                  <label>Graph : </label>
                  <Dropdown
                    value={this.state.formConfig.graphDecisionTree ?? ""}
                    appendTo={document.body}
                    options={this.props.decisionTrees.map(e => e.name)}
                    onChange={(e) => {
                      const formConfig = this.state.formConfig
                      formConfig.graphDecisionTree = e.value
                      this.setState({
                        formConfig
                      })
                    }}
                    placeholder="Graph decision tree"
                    required={this.state.formConfig.type === FormType.GRAPH}
                  />
                </div>
                <div>
                  <label>Schema : </label>
                  <Dropdown
                    value={this.state.formConfig.diagramDecisionTree ?? ""}
                    appendTo={document.body}
                    options={this.props.decisionTrees.map(e => e.name)}
                    onChange={(e) => {
                      const formConfig = this.state.formConfig
                      formConfig.diagramDecisionTree = e.value
                      this.setState({
                        formConfig
                      })
                    }}
                    placeholder="Diagram decision tree"
                    required={this.state.formConfig.type === FormType.GRAPH}
                  />
                </div>
              </>

            )}



          </div>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(FormEditorSidebar);
