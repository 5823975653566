import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

const REGEX_DISPOSITION = /filename="(.+)"/;

export class FileUtils {

  /**
   * Download a file via AxiosResponse<Blob>
   */
  static downloadFile(response: AxiosResponse<Blob>) {
    const filename = FileUtils.getFileName(response);
    if (filename) {
      saveAs(response.data, filename);
    }
  }

  static getFileName(response: AxiosResponse<Blob>) {
    const contentDisposition = response.headers['content-disposition'];
    if (!contentDisposition) {
      return null;
    }


    const result = REGEX_DISPOSITION.exec(contentDisposition);

    if (!result) {
      return null;
    }
    return result[1];
  }
}