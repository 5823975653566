import { Select } from "@bbri/ui";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./SelectLanguage.scss";

interface Props extends WithTranslation {
}

class SelectLanguage extends Component<Props> {
  static languages = ["fr", "nl"];

  handleSelectChange(value: string) {
    this.props.i18n.changeLanguage(value);
  }

  render() {
    const options: { id: string; name: string }[] = [];

    for (const language of SelectLanguage.languages) {
      options.push({
        id: language,
        name: language.toUpperCase(),
      });
    }

    return (
      <div className="select-language">
        <Select
          selection={{
            id: this.props.i18n.language,
            name: this.props.i18n.language.toUpperCase(),
          }}
          displayProperty="name"
          options={options}
          onChange={(item: { id: string; name: string }) =>
            this.handleSelectChange(item.id)
          }
        />
      </div>
    );
  }
}

export default withTranslation()(SelectLanguage);
