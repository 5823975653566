import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Input } from "@bbri/ui";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FileUtils } from "../../shared/utils/FileUtils";
import { Script } from "../models/Script";
import { ScriptDTO } from "../dto/ScriptDTO";
import { RichText } from "prismic-reactjs";
import { FormService } from "../../form/services/FormService";
import { AdminScriptsService } from "../services/AdminScriptsService";


import Editor from "@monaco-editor/react";

interface Props extends WithTranslation {

}

interface States {
  scripts: Script[];
  displayConfirmation: boolean;
  displayNewScriptConfirmation: boolean;
  toDelete: string | null;
  script: Script;
  editorText: string;
  editorScriptName: string;
}

class AdminEditorJs extends React.Component<Props, States> {
  private readonly scriptService: AdminScriptsService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.scriptService = new AdminScriptsService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;
    this.formService = new FormService();
    this.state = {
      scripts: [],
      displayConfirmation: false,
      displayNewScriptConfirmation: false,
      toDelete: null,
      script: new Script("", ""),
      editorText: "",
      editorScriptName: ""
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.resetEditorText = this.resetEditorText.bind(this);
    this.handleOneUpload = this.handleOneUpload.bind(this);
  }

  componentDidMount() {
    this.getScripts();
  }


  /**
   * Refresh scripts
   */
  getScripts() {
    this.scriptService
      .getMany()
      .then((response: AxiosResponse<Script[]>) => {
        this.setState({ scripts: response.data });
      });
  }

  getScriptToViewText(str: string) {
    if (!str || !str.includes(".js")) {
      return;
    }

    this.scriptService
      .getOne(str)
      .then((response: AxiosResponse<string>) => {

        this.setState({ editorText: response.data, editorScriptName: str });

      });
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }
    this.scriptService
      .uploadMany(e.files)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.formService.t(this.t, "ui_admin_script_upload_toast_success", false),
        });
        this.fileUpload?.clear();
        this.getScripts();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `${this.formService.t(this.t, "ui_admin_script_upload_toast_error", false)} : ${message}`,
        });
      });
  }


  /**
   * On upload for one script
   */
  handleOneUpload() {

    let fileName = this.state.editorScriptName;

    if (!fileName) {
      return;
    }

    let extensionPart = fileName.substring(fileName.lastIndexOf('.'));
    const allowedTypes = ['.js'];

    // = Validate the types
    if (!allowedTypes.includes(extensionPart)) {
      fileName += ".js";
    }

    const dto = new ScriptDTO(fileName, this.state.editorText);

    this.scriptService
      .uploadOne(dto)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.formService.t(this.t, "ui_admin_script_upload_one_toast_success", false),
        });
        this.resetEditorText();
        this.getScripts();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `${this.formService.t(this.t, "ui_admin_script_upload_one_toast_error", false)} : ${message}`,
        });
      });
  }

  /**
   * On download
   */
  handleDownload(name: string) {
    this.scriptService
      .downloadOne(name)
      .then((response: AxiosResponse<Blob>) => {
        FileUtils.downloadFile(response);
      });
  }

  /**
   * Delete script
   */
  handleDelete() {
    if (!this.state.toDelete) {
      return;
    }

    this.scriptService.deleteOne(this.state.toDelete).then(() => {
      this.toast?.show({
        severity: SeverityEnum.SUCCESS,
        detail: this.t("ui_admin_script_delete_toast_success"),
      });
      this.setState({ displayConfirmation: false, toDelete: null });
      this.getScripts();
      this.resetEditorText();
    });
  }


  renderDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.handleDelete()}
          autoFocus
        />
      </div>
    );
  }

  actionBodyTemplate(rowData: Script) {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-secondary p-mr-1"
          onClick={() => this.handleDownload(rowData.fileName)}
        ></Button>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() =>
            this.setState({ displayConfirmation: true, toDelete: rowData.fileName })
          }
        ></Button>
      </React.Fragment>
    );
  }

  actionHeaderTemplate(rowData: Script) {
    return (
      <React.Fragment>

      </React.Fragment>
    );
  }

  handleEditorChange(value, event) {

    this.setState({ editorText: value });
  }

  onRowClick(event) {
    this.getScriptToViewText(event.originalEvent.target.innerHTML);
  }


  resetEditorText() {
    this.setState({
      editorText: "//create a new script",
      displayNewScriptConfirmation: false,
      editorScriptName: ""
    });
  }

  validationWindowForNewScript() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayNewScriptConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.resetEditorText()}
          autoFocus
        />
      </div>
    );
  }


  render() {
    return (
      <Card title={this.formService.t(this.t, "ui_admin_editor_js_title", false)}>
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="p-grid">

          <div className="p-col-12 p-d-flex p-jc-center">

            <span className="p-float-label">
              <Input.Text
                value={this.state.editorScriptName}
                onChange={(e) => this.setState({ editorScriptName: e.currentTarget.value })}

                placeholder="Script name"
                required={true}
                style={{ width: '20rem' }}
              />
            </span>

          </div>

          <div className="p-col-12">
            {/* <InputTextarea 
            value={this.state.value} 
            onChange={(e) => this.setState({ value: e.currentTarget.value })}
            rows={5} 
            cols={50} 
            autoResize /> */}

            <Editor
              height="20rem"
              defaultLanguage="javascript"
              defaultValue="// write down javascript code"
              value={this.state.editorText}
              onChange={this.handleEditorChange}
            />
          </div>

          <div className="p-col-12 p-d-flex p-jc-center">
            <Button
              label="New script"
              icon="pi pi-file"
              className="p-button p-button-info  p-m-2"
              onClick={() =>
                this.setState({ displayNewScriptConfirmation: true })
              }
            />
            <Button
              label="Submit"
              icon="pi pi-check"
              className="p-button p-button-info  p-m-2 "
              onClick={this.handleOneUpload}
            />
          </div>


          <div className="p-col-12">
            <DataTable
              value={this.state.scripts}
              rows={5}
              paginator={true}
              rowsPerPageOptions={[5, 10, 20]}
              onRowClick={this.onRowClick.bind(this)}
            >
              <Column
                field="fileName"
                filter
                filterPlaceholder={RichText.asText(this.t(
                  "ui_admin_script_search_by_name",
                  { returnObjects: true }
                ))}
                header={this.formService.t(this.t, 'ui_admin_script_name', true, false)}
              />
              <Column
                body={(rowData: Script) =>
                  this.actionBodyTemplate(rowData)
                }
                headerStyle={{ width: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
            <Dialog
              header="Confirmation"
              visible={this.state.displayConfirmation}
              modal
              style={{ width: "350px" }}
              footer={this.renderDialogFooter()}
              onHide={() => this.setState({ displayConfirmation: false })}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
                />
                <span>Are you sure you want to proceed?</span>
              </div>
            </Dialog>


            <Dialog
              header="Confirmation"
              visible={this.state.displayNewScriptConfirmation}
              modal
              style={{ width: "350px" }}
              footer={this.validationWindowForNewScript()}
              onHide={() => this.setState({ displayNewScriptConfirmation: false })}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
                />
                <span>Are you sure you want to proceed?</span>
              </div>
            </Dialog>
          </div>
        </div>
      </Card>
    );
  }

}

export default withTranslation()(AdminEditorJs);


