import draw2d from "draw2d";
import {ShapeError} from "../models/shape-error";
import {ShapeErrorTypes} from "../enums/shape-error-types.enum";
const draw2d2 = draw2d as any;
const TRANSITION_COLOR = '#6cc93a';

export class TransitionRHS extends draw2d2.Connection {
    NAME = 'rhs';

    label: any = null;

    constructor(sourceFigure?: any, targetFigure?: any) {
        super({
            router: new draw2d2.layout.connection.VertexRouter(),
            name: 'rhs',
            selectable: false,
            userData: {
                name: 'rhs'
            }
        });

        if(sourceFigure && targetFigure) {
            for (const port of sourceFigure.hybridPorts.data) {
                if(port.name === 'down') {
                    this.setSource(port)
                }
            }
            for (const port of targetFigure.hybridPorts.data) {
                if(port.name === 'up') {
                    this.setTarget(port)
                }
            }
        }

        // add arrow decorator
        let bar = new draw2d2.decoration.connection.BarDecorator();
        bar.setDimension(50, 30);
        bar.setBackgroundColor(TRANSITION_COLOR);
        this.setSourceDecorator(bar);
        this.setColor(TRANSITION_COLOR);

        this._displayInfo();
    }

    private _displayInfo() {

        // remove old table
        if (this.tableInfo) {
            this.remove(this.tableInfo);
        }

        this.tableInfo = new draw2d.shape.layout.TableLayout({
            stroke: 0,
            resizeable: true,
            bgColor: '#FFFFFF',
        });

        let header: any[] = [];

        header.push(this.label ?? this.NAME);

        this.tableInfo.addRow(...header);
        this.tableInfo.setPadding(0);

        this.add(this.tableInfo, new draw2d.layout.locator.PolylineMidpointLocator());
    }

    /**
     * Add decorator in the persistent attributes
     */
    getPersistentAttributes() {
        let memento = super.getPersistentAttributes();

        if (memento.source.decoration) {
            memento.source.decorator = {
                bgColor: this.sourceDecorator.backgroundColor.hashString,
                height: this.sourceDecorator.height,
                width: this.sourceDecorator.width
            };
        }

        if (memento.target.decoration) {
            memento.target.decorator = {
                bgColor: this.targetDecorator.backgroundColor.hashString,
                height: this.targetDecorator.height,
                width: this.targetDecorator.width
            };
        }

        return memento;
    }

    setPersistentAttributes(memento) {
        super.setPersistentAttributes(memento);
        this.attr({ color: TRANSITION_COLOR });
        if (this.sourceDecorator && memento.source.decorator) {
            this.sourceDecorator.setBackgroundColor(TRANSITION_COLOR);
            this.sourceDecorator.setDimension(memento.source.decorator.width, memento.source.decorator.height);
        }

        if (this.targetDecorator && memento.target.decorator) {
            this.targetDecorator.setBackgroundColor(TRANSITION_COLOR);
            this.targetDecorator.setDimension(memento.target.decorator.width, memento.target.decorator.height);
        }
    }

    setLabel(label: string): void {
        this.label = label;
        this._displayInfo();
    }
}
