import { APIService } from '../../shared/services/APIService';

export class AdminRequirementsService extends APIService {

  /**
   * Upload and replace the requirements
   *
   * @role Admin
   */
  uploadRequirements(file: File) {
    const formData = new FormData();
    formData.append('requirements', file);
    return this.http.put<void>('/forms/default/requirements/upload', formData);
  }

  /**
   * Download the current requirements
   *
   * @role Admin
   */
  downloadRequirements() {
    return this.http.get<Blob>('/forms/default/requirements/download', { responseType: 'blob' });
  }
}