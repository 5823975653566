import { Radio } from "@bbri/ui";
import { TFunction } from "i18next";
import React, { ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormService } from "../services/FormService";

interface Props extends WithTranslation {
  name: string;
  currentValue: any;
  values: any[];
  onValueChange: (v: any) => void;
}

class FormRadioList extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  render() {
    const list: ReactNode[] = [];
    const values = this.props.values;
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const key = this.props.name + i;
      list.push(
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a key={`a_${key}`} onClick={() => this.props.onValueChange(value)}>
          <Radio
            id={key}
            key={key}
            label={this.formService.t(this.t, value, true)}
            checked={this.props.currentValue === value}
            onChange={() => this.props.onValueChange(value)}
          />
        </a>
      );
    }

    return list;
  }
}

export default withTranslation()(FormRadioList);
